import React from 'react'

import './Testimonial.css'

import rameshImage from '../../assets/images/ramesh.jpeg'
import shrutiImage from '../../assets/images/shruti.jpeg'

export default function Testimonials() {
  return (
    <section className='as_customer_wrapper as_padderBottom80 as_padderTop80'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 text-center'>
            <h1 className='as_heading as_heading_center'>
              What Our Customers Say
            </h1>
            <div className='row as_customer_slider testimonial-container'>
              <div className='col-lg-4 col-md-4'>
                <div className='as_customer_box text-center'>
                  <span className='as_customer_img'>
                    <img src={rameshImage} alt='customer' />
                    <span>
                      <img src='assets/images/svg/quote1.svg' alt='' />
                    </span>
                  </span>
                  <p className='as_margin0'>
                    We engaged prohits for my 11th day karma of my deceased
                    mother on 16 th November 21, the function was well arranged
                    in total, by two pandits with reasonable cost. I am
                    satisfied 🙏
                  </p>
                  <h3>Ramesh G.</h3>
                </div>
              </div>

              <div className='col-lg-4 col-md-4'>
                <div className='as_customer_box text-center'>
                  <span className='as_customer_img'>
                    <img src={shrutiImage} alt='customer' />
                    <span>
                      <img src='assets/images/svg/quote1.svg' alt='' />
                    </span>
                  </span>
                  <p className='as_margin0'>
                    We liked your professionalism, you were on time with regard
                    to the response. Thank you for arranging so nicely for us.
                    The event turned out to be awesome than expected. Thank you
                    veru much.
                  </p>
                  <h3>Shruthi Hebbar</h3>
                </div>
              </div>

              <div className='col-lg-4 col-md-4'>
                <div className='as_customer_box text-center'>
                  <span className='as_customer_img'>
                    <img
                      src='https://anuragsinghbam.com/images/gautham.webp'
                      alt='customer'
                    />
                    <span>
                      <img src='assets/images/svg/quote1.svg' alt='' />
                    </span>
                  </span>
                  <p className='as_margin0'>
                    We are very happy with the outcome of your services, you are
                    very patient and I’m convinced we can definitely leave our
                    worries to you. I will recommend to my family and friends. Thank you!
                  </p>
                  <h3>Gautham Chengappa</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
