import React, { useContext, useState } from 'react'
import GoogleLogin from 'react-google-login'
import { UserContext } from '../../context/UserContext'
import isMobile from 'validator/lib/isMobilePhone'

import './Popup.css'

const Popup = ({ popupOpen, setPopupOpen, setToken }) => {
  const { 2: getUserDetails, 3: getBaseUrl } = useContext(UserContext)
  const clientId = process.env.REACT_APP_CLIENT_ID

  const BASE_POST_URL = getBaseUrl()

  const signupFormFields = [
    {
      name: 'name',
      placeholder: 'Enter your name',
      type: 'text',
    },
    {
      name: 'mobile',
      placeholder: 'Enter mobile number',
      type: 'tel',
      dataSubmitType: 'signup',
      onChange: (e) => {},
    },
    {
      name: 'otp',
      placeholder: 'Enter four digit OTP',
      type: 'text',
      dataSubmitType: 'signup-otp',
    },
    {
      name: 'password',
      placeholder: 'Enter new password',
      type: 'password',
    },
  ]

  const loginFormFields = [
    {
      name: 'mobile',
      placeholder: 'Enter mobile number',
      type: 'tel',
      dataSubmitType: 'login',
    },
    {
      name: 'otp',
      placeholder: 'Enter four digit OTP',
      type: 'text',
      dataSubmitType: 'login-otp',
    },
    {
      name: 'password',
      placeholder: 'Enter your password',
      type: 'password',
    },
  ]

  const [popupFields, setPopupFields] = useState(loginFormFields)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(
    'Incorrect username or password.'
  )
  const [mobileInput, setMobileInput] = useState('')
  const [mobileValid, setMobileValid] = useState(false)
  const [otpDisabled, setOtpDisabled] = useState(true)
  const [showError, setShowError] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [isSignupForm, setIsSignupForm] = useState(false)
  const [loginWithOtp, setLoginWithOtp] = useState(false)
  const [otpRequested, setOtpRequested] = useState(false)

  const closePopup = () => {
    document.body.classList.remove('modal-open')
    setPopupOpen(false)
    setOtpSent(false)
    setIsError(false)
    setOtpDisabled(true)
  }

  const formHandler = async (e) => {
    e.preventDefault()
    if (!mobileValid) {
      setShowError(true)
      return
    }
    setIsLoading(true)
    const formDataIterator = new FormData(e.target)

    const formData = {}

    formDataIterator.forEach((value, key) => {
      formData[key] = value
    })

    if (!isSignupForm && loginWithOtp) {
      formData.password = ''
    }
    if (!isSignupForm && !loginWithOtp) {
      formData.otp = ''
    }

    const postURL = BASE_POST_URL + (formData.name ? 'signup' : 'login')

    try {
      const response = await fetch(postURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      const responseData = await response.json()

      if (responseData.token) {
        setToken(responseData.token)
        localStorage.setItem('token', responseData.token)
        closePopup()
        e.target.reset()
        getUserDetails(responseData.token)
        setIsLoading(false)
      }

      if (responseData.message) {
        setIsError(true)
        setErrorMessage(responseData.message)
        setIsLoading(false)
      }

      if (responseData.errors) {
        setIsError(true)
        setErrorMessage(responseData.errors[0].msg)
        setIsLoading(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const responseGoogle = (googleData) => {
    setIsLoading(true)

    if (googleData.error) {
      setIsLoading(false)
      return
    }

    const { name, email, imageUrl } = googleData.profileObj
    const postURL = BASE_POST_URL + '/google/auth'

    try {
      fetch(postURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          imageUrl,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.token) {
            setToken(data.token)
            localStorage.setItem('token', data.token)
            closePopup()
            getUserDetails(data.token)
            setIsLoading(false)
          }
        })
    } catch (err) {
      console.log(err)
    }
  }

  const requestOTP = () => {
    setOtpRequested(true)
    const postURL = BASE_POST_URL + 'otp'

    try {
      fetch(postURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          mobile: mobileInput,
          signUp: isSignupForm ? true : false,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.errors) {
            setIsError(true)
            setErrorMessage(data.errors[0].msg)
            setIsLoading(false)
            setOtpRequested(false)
            return
          }
          setIsError(false)
          setOtpRequested(false)
          setOtpSent(true)
          setOtpDisabled(true)
          console.log(data)
        })
    } catch (err) {
      console.log(err)
    }
  }

  const handleMobileChange = (e) => {
    setMobileInput(e.target.value)
    if (isMobile(e.target.value, ['en-IN'], { strictMode: false })) {
      setMobileValid(true)
      setOtpDisabled(false)
      setShowError(false)
      return
    }
    setOtpSent(false)
    setMobileValid(false)
    setOtpDisabled(true)
  }

  return (
    <div
      id='as_login'
      className='modal'
      tabIndex={-1}
      onClick={(e) => {
        if (e.target.id === 'as_login' || e.target.id === 'modal-dialog') {
          closePopup()
        }
      }}
      aria-labelledby='as_login'
      style={{
        display: `${popupOpen ? 'block' : 'none'}`,
      }}
      aria-hidden='true'
    >
      <div id='modal-dialog' className='modal-dialog search-box'>
        {/* Modal content*/}
        <div className='modal-content'>
          <div className={`modal-header ${isLoading ? 'hidden' : ''}`}>
            <button
              type='button'
              className='close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={closePopup}
            >
              ×
            </button>
            <h4 className='modal-title'>
              {popupFields.length < 4 ? 'Login' : 'Sign Up'}
            </h4>
          </div>
          <div className={`modal-body ${isLoading ? 'hidden' : ''}`}>
            <p className={`error-msg ${!isError ? 'not-show' : ''}`}>
              {errorMessage}
              <span
                onClick={() => {
                  setIsError(false)
                }}
              >
                &times;
              </span>
            </p>
            <form onSubmit={formHandler}>
              {popupFields.map((field) => {
                return (
                  <div
                    className={`form-group form-group-${field.name} ${
                      ((field.dataSubmitType === 'login-otp' ||
                        field.name === 'mobile') &&
                        loginWithOtp) ||
                      field.dataSubmitType === 'signup'
                        ? 'login-with-otp'
                        : ''
                    }`}
                    key={field.name}
                  >
                    <input
                      type={field.type}
                      name={field.name}
                      placeholder={field.placeholder}
                      data-form-type={field.dataSubmitType}
                      className={`form-control`}
                      autoComplete='off'
                      id={field.name}
                      onChange={
                        field.name === 'mobile' ? handleMobileChange : () => {}
                      }
                      maxLength={field.name === 'mobile' ? 10 : ''}
                    />
                    {field.name === 'mobile' ? (
                      <>
                        <span
                          className={`send-otp ${
                            otpDisabled ? 'disabled' : ''
                          } ${otpSent ? 'otp-sent' : ''}  ${
                            otpRequested ? 'otp-requested' : ''
                          }`}
                          onClick={otpDisabled ? () => {} : requestOTP}
                        >
                          {otpSent ? (
                            <>
                              <span className='tick-icon'>✓</span>
                              <span> OTP Sent</span>
                            </>
                          ) : (
                            'Get OTP'
                          )}
                        </span>
                        <div
                          className={`error-message ${
                            showError ? 'show-error' : ''
                          }`}
                        >
                          Please enter a valid mobile number
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                )
              })}

              {(() => {
                if (popupFields.length < 4) {
                  return (
                    <div className='form-group'>
                      <div className='as_login_data'>
                        <span
                          className='login-with-otp'
                          onClick={() => {
                            setLoginWithOtp(!loginWithOtp)
                          }}
                        >
                          {!loginWithOtp
                            ? 'Login With OTP'
                            : 'Login With Password'}
                        </span>
                      </div>
                    </div>
                  )
                }
              })()}

              <div className='text-center'>
                <button className='as_btn login-button'>
                  {popupFields.length < 4 ? 'Login' : 'Sign Up'}
                </button>
              </div>
            </form>
            <p className='text-center as_margin0'>
              {popupFields.length < 4
                ? 'Create An Account? '
                : 'Have An Account? '}
              <span
                className='as_orange as_signup'
                onClick={() => {
                  if (popupFields.length < 4) {
                    setIsSignupForm(true)
                    setPopupFields(signupFormFields)
                  } else {
                    setIsSignupForm(false)
                    setPopupFields(loginFormFields)
                  }
                }}
              >
                {popupFields.length < 4 ? 'Sign Up' : 'Login'}
              </span>
            </p>
            <p className='google-login'>
              <GoogleLogin
                clientId={clientId}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
              />
            </p>
          </div>
          <div className={`loading ${!isLoading ? 'hidden' : ''}`}>
            <div className='circle'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Popup
