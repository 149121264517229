import React from 'react'

import './Alert.css'

export default function Alert(props) {
  return (
    <div className='Alert'>
      <p>Logged In Successfully</p>
    </div>
  )
}
