import React, { useEffect, useState, useContext } from 'react'
import AirDatepicker from 'air-datepicker'
import localeEn from 'air-datepicker/locale/en'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { UserContext } from '../../context/UserContext'
import isMobile from 'validator/lib/isMobilePhone'

import emailjs from 'emailjs-com'

import './Checkout.css'

import QRCode from '../../assets/images/checkout/PhonePe QR Code.jpeg'
import PhonePeLogo from '../../assets/images/checkout/PhonePe Logo.svg'
import poojaArticles from '../../Data/PoojaArticles'

const Checkout = ({ location }) => {
  const [token, setToken, showAlert, setShowAlert] = useContext(AuthContext)
  const [user, setUser, getUserDetails, getBaseUrl] = useContext(UserContext)
  const [order, setOrder] = useState({})

  const [otpDisabled, setOtpDisabled] = useState(true)
  const [showError, setShowError] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [otpRequested, setOtpRequested] = useState(false)
  const [otpVerified, setOtpVerified] = useState(false)

  const [paymentActive, setPaymentActive] = useState(false)
  const [thanksActive, setThanksActive] = useState(false)
  const [date, setDate] = useState(location.state.date)
  const [time, setTime] = useState(location.state.time)
  const [brahminActive, setBrahminActive] = useState(true)
  const [tncActive, setTncActive] = useState(false)
  const [checked, setChecked] = useState(true)
  const [paymentMode, setPaymentMode] = useState('')
  const [serviceMode, setServiceMode] = useState('Offline')
  const [includePoojaItems, setIncludePoojaItems] = useState('Yes')

  const [userDetails, setUserDetails] = useState({})
  const [isUserValid, setIsUserValid] = useState(false)
  const [nameInput, setNameInput] = useState('')
  const [mobileInput, setMobileInput] = useState('')
  const [mobileValid, setMobileValid] = useState(false)
  const [emailInput, setEmailInput] = useState('')
  const [pincodeInput, setPincodeInput] = useState('')
  const [addressInput, setAddressInput] = useState('')
  const [addedItemsList, setAddedItemsList] = useState([])
  const [isItemsSuggestionOpen, setIsItemsSuggestionOpen] = useState(false)

  const [isPopupActive, setIsPopupActive] = useState(false)
  const [poojaArticlesList, setPoojaArticlesList] = useState(poojaArticles)

  const BASE_POST_URL = getBaseUrl()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

 

  // useEffect(() => {

  // }, [paymentMode])

  useEffect(() => {
    if (
      userDetails.name &&
      userDetails.mobile &&
      userDetails.email &&
      userDetails.pincode &&
      userDetails.address &&
      mobileValid
    ) {
      setIsUserValid(true)
    } else {
      setIsUserValid(false)
    }
  }, [userDetails])

  useEffect(() => {
    if (isUserValid) {
      setOtpDisabled(false)
    } else {
      setOtpDisabled(true)
    }
  }, [isUserValid])

  const requestOTP = () => {
    setOtpRequested(true)
    const postURL = BASE_POST_URL + 'otp'

    try {
      fetch(postURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          mobile: mobileInput,
          order: true,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.errors) {
            console.log(data)
            setOtpRequested(false)
            return
          }
          setOtpRequested(false)
          setOtpSent(true)
          setOtpDisabled(true)
          console.log(data)
        })
    } catch (err) {
      console.log(err)
    }
  }

  const handleNameChange = (e) => {
    setNameInput(e.target.value)
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
  }

  const handleMobileChange = (e) => {
    setMobileInput(e.target.value)
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
    if (isMobile(e.target.value, ['en-IN'], { strictMode: false })) {
      setMobileValid(true)
      setShowError(false)
      return
    }
    setOtpSent(false)
    setMobileValid(false)
  }

  const handleEmailChange = (e) => {
    setEmailInput(e.target.value)
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
  }

  const handlePincodeChange = (e) => {
    setPincodeInput(e.target.value)
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
  }

  const handleAddressesChange = (e) => {
    setAddressInput(e.target.value)
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
  }



  const verifyOtp = async (e) => {
    // if (!mobileValid) {
    //   setShowError(true)
    //   return
    // }
    // setIsLoading(true)
    setOtpRequested(true)

    const postURL = BASE_POST_URL + 'login-with-order'

    try {
      const response = await fetch(postURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...userDetails, otp: e.target.value }),
      })

      const responseData = await response.json()

      console.log(responseData)

      if (responseData.token) {
        setToken(responseData.token)
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 5000)
        setOtpVerified(true)
        setOtpRequested(false)
        localStorage.setItem('token', responseData.token)
        getUserDetails(responseData.token)
      }

      if (responseData.message) {
        // setIsError(true)
        // setErrorMessage(responseData.message)
        // setIsLoading(false)
      }

      if (responseData.errors) {
        // setIsError(true)
        // setErrorMessage(responseData.errors[0].msg)
        // setIsLoading(false)
        setOtpRequested(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const submitOrder = async (userDetails, orderDetails) => {
    const postURL = BASE_POST_URL + 'order'

    const orderPayload = {
      userDetails,
      orderDetails,
    }

    try {
      const response = await fetch(postURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Auth: token,
        },
        body: JSON.stringify(orderPayload),
      })

      const responseData = await response.json()
      console.log(responseData)
    } catch (err) {
      console.log(err)
    }
  }

  const otpHandler = (e) => {
    console.log(userDetails)
    if (e.target.value.length === 4 && mobileValid) {
      verifyOtp(e)
    }
  }

  const [price, setPrice] = useState(
    location.state.priceWithArticles || location.state.priceWithoutArticles
  )

  useEffect(() => {
    new AirDatepicker('.as_datepicker', {
      locale: localeEn,
      onSelect: (e) => {
        setDate(e.formattedDate)
      },
      dateFormat(date) {
        return date.toLocaleString('en', {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        })
      },
    })
    new AirDatepicker('.as_timepicker', {
      locale: localeEn,
      timepicker: true,
      onlyTimepicker: true,
      onSelect: (e) => {
        setTime(e.formattedDate)
      },
    })
  })

  const sendMessage = (e) => {
    window.scrollTo(0, 255)

    e.preventDefault()

    const userDetailsInput = e.target.querySelectorAll('.user-details-input')

    const userDetails = {}

    userDetailsInput.forEach((input) => {
      userDetails[input.name] = input.value
    })

    const orderDetails = {
      eventName: location.state.serviceName,
      eventDate: date,
      eventTime: time,
      price,
      serviceMode,
      includePoojaItems,
    }
    submitOrder(userDetails, orderDetails)
    setOrder(orderDetails)

    //My Mail

    let checkoutFormDetails = {}

    const formDataIterator = new FormData(e.target)

    formDataIterator.forEach((value, key) => {
      checkoutFormDetails[key] = value
    })

    if (user.name) {
      checkoutFormDetails.name = user.name
    }

    if (user.mobile) {
      checkoutFormDetails.mobile = user.mobile
    }

    if (user.email) {
      checkoutFormDetails.email = user.email
    }

    if (user.pincode) {
      checkoutFormDetails.pincode = user.pincode
    }

    if (user.address) {
      checkoutFormDetails.address = user.address
    }


    checkoutFormDetails.itemsList = addedItemsList.toString().replaceAll(',', ', ')

    // emailjs
    //   .send(
    //     'service_41ltenp',
    //     'template_9hxofan',
    //     checkoutFormDetails,
    //     'user_0ZIyW4XZuIFbQzbYm3crk'
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text)
    //     },
    //     (error) => {
    //       console.log(error.text)
    //     }
    //   )

    // Harigurus Mail

    emailjs
      .send(
        'service_p6qjgku',
        'template_fkhkcaf',
        checkoutFormDetails,
        'user_S89rFK9sWCIgbwBrBLdYf'
      )
      .then(
        (result) => {
          console.log(result.text)
        },
        (error) => {
          console.log(error.text)
        }
      )

    setPaymentActive(true)

    if (document.activeElement.id === 'pay-now') {
      setThanksActive(false)
    } else if (document.activeElement.id === 'pay-later') {
      setThanksActive(true)
    } else if (document.activeElement.value === 'Payment') {
      setThanksActive(false)
    }

    e.target.reset()
  }

  return (
    <>
      <section className='as_breadcrum_wrapper'>
      <title>Harigurus | Checkout</title>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h1>Checkout</h1>
              <ul className='breadcrumb'>
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <Link to={`/checkout`}>Checkout</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className='as_checkout_wrapper as_padderBottom80 as_padderTop80'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-10 col-sm-12 col-xs-12 col-lg-offset-1'>
              <div className='checkout_wrapper_box'>
                <ul id='progressbar'>
                  <li className='active as_btn' data-active={1}>
                    Billing Details
                  </li>
                  <li
                    className={`as_btn payment-button ${
                      paymentActive ? 'active' : ''
                    }`}
                    data-active={2}
                  >
                    <input type='submit' form='purchaseForm' value='Payment' />
                  </li>
                  <li
                    className={`as_btn ${thanksActive ? 'active' : ''}`}
                    data-active={3}
                  >
                    Done
                  </li>
                </ul>
                <div
                  className='woocommerce_billing text-left step'
                  data-target={1}
                  style={{ display: `${paymentActive ? 'none' : 'block'}` }}
                >
                  <div className='serviceName-price'>
                    <h3>{location.state.serviceName}</h3>
                    <h3 className='as_price'>₹{price}</h3>
                  </div>
                  <div className='row'>
                    <form id='purchaseForm' onSubmit={sendMessage}>
                      <input
                        type='hidden'
                        name='serviceName'
                        value={location.state.serviceName}
                      />
                      <input
                        type='hidden'
                        name='fromHomepage'
                        value={location.state.fromHomepage || 'No'}
                      />
                      <input
                        type='hidden'
                        name='paymentMode'
                        value={paymentMode}
                      />
                      <div className='col-lg-6 col-md-6'>
                        <div className='form-group'>
                          <input
                            type='text'
                            placeholder={user.name || 'Name*'}
                            disabled={user.name}
                            className='form-control user-details-input'
                            name='name'
                            onChange={handleNameChange}
                            required
                          />
                        </div>
                      </div>

                      <div className='col-lg-6 col-md-6'>
                        <div className='form-group'>
                          <input
                            type='text'
                            placeholder={user.mobile || 'Phone*'}
                            disabled={user.mobile}
                            className='form-control user-details-input'
                            maxLength='10'
                            name='mobile'
                            onChange={handleMobileChange}
                            required
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <div className='form-group'>
                          <input
                            type='email'
                            placeholder={user.email || 'Email*'}
                            disabled={user.email}
                            className='form-control user-details-input'
                            name='email'
                            onChange={handleEmailChange}
                            required
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <div className='form-group'>
                          <input
                            type='text'
                            placeholder={user.pincode || 'Pincode*'}
                            disabled={user.pincode}
                            className='form-control user-details-input'
                            name='pincode'
                            maxLength='6'
                            onChange={handlePincodeChange}
                            required
                          />
                        </div>
                      </div>

                      <div
                        className={
                          token ? 'col-lg-12 col-md-12' : 'col-lg-6 col-md-6'
                        }
                      >
                        <div className='form-group'>
                          <input
                            placeholder={user.address || 'Address*'}
                            disabled={user.address}
                            className='form-control user-details-input'
                            name='address'
                            onChange={handleAddressesChange}
                            required
                          />
                        </div>
                      </div>

                      {!token && (
                        <div className='col-lg-6 col-md-6'>
                          <div className='form-group otp-container'>
                            <input
                              placeholder={'OTP*'}
                              className='form-control user-details-input'
                              name='address'
                              maxLength='4'
                              onChange={otpHandler}
                            />
                            <span
                              className={`send-otp ${
                                otpDisabled ? 'disabled' : ''
                              } ${otpSent ? 'otp-sent' : ''}  ${
                                otpRequested ? 'otp-requested' : ''
                              }`}
                              onClick={otpDisabled ? () => {} : requestOTP}
                            >
                              {(() => {
                                if (otpVerified) {
                                  return (
                                    <>
                                      <span className='tick-icon'>✓</span>
                                      <span> OTP Verified</span>
                                    </>
                                  )
                                }

                                if (otpSent) {
                                  return (
                                    <>
                                      <span className='tick-icon'>✓</span>
                                      <span> OTP Sent</span>
                                    </>
                                  )
                                }
                                return 'Get OTP'
                              })()}
                            </span>
                          </div>
                        </div>
                      )}

                      <div className='col-lg-6 col-md-6'>
                        <label>Caste</label>
                        <div className='form-group as_select_box'>
                          <select
                            onChange={() => {
                              setBrahminActive(!brahminActive)
                            }}
                            className='form-control user-details-input'
                            name='caste'
                          >
                            <option>Brahmin</option>
                            <option>Non Brahmin</option>
                          </select>
                        </div>
                      </div>

                      <div className='col-lg-6 col-md-6'>
                        <label>Subcaste</label>
                        <div className='form-group as_select_box'>
                          <select
                            disabled={brahminActive ? false : true}
                            className='form-control user-details-input'
                            name='subcaste'
                          >
                            <option>Madhwa</option>
                            <option>Smarta</option>
                            <option>Iyengar</option>
                            <option>Iyer</option>
                          </select>
                        </div>
                      </div>

                      <div className='col-lg-6 col-md-6'>
                        <label>Date</label>
                        <div className='as_input_feild form-group'>
                          <input
                            type='text'
                            className='form-control as_datepicker'
                            placeholder='DD/MM/YYYY'
                            required
                            name='date'
                            value={date}
                            autoComplete='off'
                            onChange={(e) => {
                              setDate(e.target.value)
                            }}
                          />
                          <span>
                            <img src='assets/images/svg/date.svg' alt='' />
                          </span>
                        </div>
                      </div>

                      <div className='col-lg-6 col-md-6'>
                        <label>Time</label>
                        <div className='as_input_feild form-group'>
                          <input
                            type='text'
                            className='form-control as_timepicker'
                            placeholder='HH:MM'
                            required
                            name='time'
                            value={time}
                            autoComplete='off'
                            onChange={(e) => {
                              setTime(e.target.value)
                            }}
                          />
                          <span>
                            <img src='assets/images/svg/time.svg' alt='' />
                          </span>
                        </div>
                      </div>

                      <div className='col-lg-6 col-md-6'>
                        <label>Service Mode</label>
                        <div className='form-group as_select_box'>
                          <select
                            className='form-control'
                            name='serviceMode'
                            onChange={(e) => {
                              setServiceMode(e.target.value)
                            }}
                          >
                            <option>Offline</option>
                            <option>Online</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <label>{`${
                          location.state.serviceName === 'In-house Cooking' ||
                          location.state.serviceName === 'Catering'
                            ? 'Include Cooking Items'
                            : 'Include Pooja Items'
                        }`}</label>
                        <div className='form-group as_select_box'>
                          <select
                            className='form-control'
                            name='includeItems'
                            onChange={(e) => {
                              setIncludePoojaItems(e.target.value)
                              if (e.target.value === 'Yes') {
                                setPrice(
                                  location.state.priceWithArticles ||
                                    location.state.priceWithoutArticles
                                )
                              } else {
                                setPrice(location.state.priceWithoutArticles)
                              }
                            }}
                          >
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                      </div>

                      <div className='col-lg-12 col-md-12'>
                        <div
                          className={`articles-popup ${
                            isPopupActive ? 'show-popup' : ''
                          }`}
                        >
                          <h2>Add Pooja Articles</h2>
                          <ul>
                            {poojaArticles.map((poojaArticle, index) => (
                              <li key={index + 1000}>
                                <input
                                  data-article-name={poojaArticle}
                                  type='checkbox'
                                />
                                {poojaArticle}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div
                          className={`popup-overlay ${
                            isPopupActive ? 'active' : ''
                          }`}
                          onClick={() => {
                            setIsPopupActive(false)
                          }}
                        ></div>
                        <label>Add Articles</label>
                        <div className='form-group as_select_box items-container'>
                          <div className='added-pooja-items'>
                            {addedItemsList.map((addedItem) => (
                              <p>
                                {addedItem}
                                <span
                                  onClick={(e) => {
                                    setAddedItemsList(
                                      addedItemsList.filter(
                                        (addedItem) =>
                                          addedItem !==
                                          e.target.parentElement.childNodes[0]
                                            .data
                                      )
                                    )
                                  }}
                                >
                                  &times;
                                </span>
                              </p>
                            ))}
                          </div>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Add Articles'
                            name='articles'
                            autoComplete='off'
                            disabled={includePoojaItems === "Yes" ? false : true}
                            onChange={(e)=> {
                              setPoojaArticlesList(poojaArticles.filter((poojaArticle) => poojaArticle.toLowerCase().includes(e.target.value.toLowerCase())))
                            }}
                            onFocus={() => {setIsItemsSuggestionOpen(true)}}
                            onBlur={(e) => {
                              setTimeout(() => {
                                setIsItemsSuggestionOpen(false)
                                setPoojaArticlesList(poojaArticles)
                                e.target.value = ''
                              },250)
                            }}
                          />

                          <div className={`items-suggestion ${isItemsSuggestionOpen ? 'open': ''}`}>
                            {poojaArticlesList.map((poojaArticle, index) => (
                              <p
                                key={index}
                                onClick={(e) => {
                                  setAddedItemsList([
                                    ...addedItemsList,
                                    e.target.innerText,
                                  ])
                                }}
                              >
                                {poojaArticle}
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className='form-group checkbox as_login_data col-lg-6 col-md-6'>
                        <label>
                          I agree to{' '}
                          <span
                            onClick={() => {
                              setTncActive(true)
                            }}
                            className='as_orange'
                          >
                            Terms & Conditions
                          </span>
                          <input
                            type='checkbox'
                            name='tnc'
                            value='Aggreed'
                            checked={checked ? true : false}
                            onChange={() => {}}
                            required
                          />
                          <span
                            onClick={() => {
                              setChecked(!checked)
                            }}
                            className='checkmark'
                          />
                        </label>
                      </div>

                      <div
                        id='as_login'
                        className='modal'
                        tabIndex={-1}
                        onClick={(e) => {
                          if (
                            e.target.id === 'as_login' ||
                            e.target.id === 'modal-dialog'
                          ) {
                            setTncActive(false)
                          }
                        }}
                        aria-labelledby='as_login'
                        style={{
                          display: `${tncActive ? 'block' : 'none'}`,
                        }}
                        aria-hidden='true'
                      >
                        <div id='modal-dialog' className='modal-dialog'>
                          {/* Modal content*/}
                          <div className='modal-content'>
                            <div className='modal-header'>
                              <button
                                type='button'
                                className='close'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                                onClick={() => {
                                  setTncActive(false)
                                }}
                              >
                                ×
                              </button>
                              <h4 className='modal-title'>
                                Terms & Conditions
                              </h4>
                            </div>
                            <div className='modal-body'>
                              <div className='as_login_box active'>
                                <p>
                                  By submitting your contact number and email
                                  ID, you authorize HariGurus to call, send SMS,
                                  messages over internet-based messaging
                                  application like WhatsApp and email and offer
                                  you information and services for the
                                  product(s) you have opted for as well as other
                                  products/services offered by HariGurus.
                                </p>
                              </div>
                            </div>
                            <div className='text-right'>
                              <span
                                onClick={() => {
                                  setChecked(true)
                                  setTncActive(false)
                                }}
                                className='as_btn'
                              >
                                I Agree
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='text-right'>
                        <button
                          onClick={() => {
                            setPaymentMode('Pay Later')
                          }}
                          id='pay-later'
                          className='as_btn'
                        >
                          Pay Later
                        </button>
                        <button
                          onClick={() => {
                            setPaymentMode('Pay Now')
                          }}
                          id='pay-now'
                          className='as_btn'
                        >
                          Pay Now
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  className='woocommerce_billing step'
                  data-target={2}
                  style={{
                    display: `${
                      paymentActive && !thanksActive ? 'block' : 'none'
                    }`,
                  }}
                >
                  <div className='row'>
                    <h2 className='as_price'>{'₹' + price}</h2>
                    <div className='qr-container'>
                      <div className='phonepe-logo'>
                        <img
                          src={PhonePeLogo}
                          className='qr-code'
                          alt='qr-code'
                        />
                        <p className='accepted'>Accepted Here</p>
                        <p className='scan-pay'>Scan & Pay Using PhonePe App</p>
                      </div>
                      <img src={QRCode} className='qr-code' alt='qr-code' />
                      <p className='upi-id'>UPI ID: 93530668021@axl</p>
                    </div>
                  </div>
                  <div className='text-right'>
                    <button
                      onClick={() => {
                        window.scrollTo(0, 0)
                        setThanksActive(true)
                      }}
                      className='as_btn next'
                      data-step={3}
                    >
                      Paid
                    </button>
                  </div>
                </div>
                <div
                  className='woocommerce_checkout_receipt step text-center'
                  data-target={4}
                  style={{ display: `${thanksActive ? 'block' : 'none'}` }}
                >
                  <h1 className='as_padderBottom10'>
                    THANK YOU FOR YOUR BOOKING!
                  </h1>
                  <p className='as_padderBottom10'>We'll contact you soon.</p>
                  <p className='see-purohits'>
                    Till then you can see the list of our Purohits and Cooks{' '}
                    <Link to='/profiles'>here</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Checkout
