import { createContext, useState } from 'react'
import UserIcon from '../assets/images/svg/user.svg'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    name: '',
    mobile: '',
    email: '',
    imageUrl: '',
    pincode: '',
    address: '',
    orders: [],
  })

  const getBaseUrl = () => {
    if (window.location.href.includes('localhost')) {
      return 'http://localhost:5000/api/user/'
    }
    return 'https://harigurus.herokuapp.com/api/user/'
  }

  const BASE_POST_URL = getBaseUrl()

  const getUserDetails = async (userToken) => {
    try {
      const response = await fetch(BASE_POST_URL + 'profile', {
        method: 'GET',
        headers: {
          Auth: userToken,
        },
      })
      const userData = await response.json()
      if (!userData.message) {
        const { name, email, mobile, imageUrl, pincode, address, orders } = userData

        setUser({
          name: name || '',
          email: email || '',
          mobile: mobile || '',
          imageUrl: imageUrl || UserIcon,
          pincode: pincode || '',
          address: address || '',
          orders: orders || [],
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <UserContext.Provider value={[user, setUser, getUserDetails, getBaseUrl]}>
      {children}
    </UserContext.Provider>
  )
}
