import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import './ServicesCard.css'

export default function ServicesCard({
  title,
  imgUrls,
  shortDescription,
  serviceUrl,
  ctaText,
  shouldCheckout,
  priceWithoutArticles,
  priceWithArticles,
}) {
  const [readMore, setReadMore] = useState(false)

  return (
    <div className='service-card'>
      <div className={`as_blog_box ${readMore ? 'read-more' : ''}`}>
        <div className='as_blog_img'>
          <Link to={`${window.location.pathname}/${serviceUrl}`}>
            <div className='service-img-container'>
              <img
                src={imgUrls[0] || 'http://via.placeholder.com/370X260'}
                alt=''
                className='img-responsive'
              />
            </div>
          </Link>

          <Link
            to={{
              pathname: shouldCheckout
                ? `/checkout`
                : `${window.location.pathname}/${serviceUrl}`,
              state: {
                serviceName: title,
                priceWithoutArticles,
                priceWithArticles,
              },
            }}
          >
            <span className='as_btn'>{ctaText || 'Book Now'}</span>
          </Link>
        </div>
        <h4 className='as_subheading'>
          <Link to={`${window.location.pathname}/${serviceUrl}`}>{title || 'Service Title'}</Link>
        </h4>
        <p className='as_font14 as_margin0'>
          {readMore ? shortDescription : shortDescription.slice(0, 85) + '... '}
          <span
            onClick={() => {
              setReadMore(!readMore)
            }}
            className='as_orange'
          >
            {readMore ? ' Show Less' : ' Show More'}
          </span>
        </p>
      </div>
    </div>
  )
}
