import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import 'swiper/swiper-bundle.css'
import './HeroSection.css'

import Astrologer from '../../assets/images/services/astrologer-services/Online Consultation.jpg'
import Purohit from '../../assets/images/purohit.jpg'
import Food from '../../assets/images/services/cook/Food.jpeg'

const HeroSection = () => {
  const [orangeHeading, setOrangeHeading] = useState(
    'Any occasion at home or office?'
  )
  const [mainHeading, setMainHeading] = useState('Book your Purohit Today')
  const [firstPara, setFirstPara] = useState(
    'Seek blessing or offer thanksgiving to the divine by performing poojas and homas without any hassle.'
  )
  const [secondPara, setSecondPara] = useState(
    'Book the services of knowledgeable purohits online, to conduct rituals as prescribed by the vedas and scriptures.'
  )

  const setPurohitContent = () => {
    setOrangeHeading('Any occasion at home or office?')
    setMainHeading('Book your Purohit Today')
    setFirstPara(
      'Seek blessing or offer thanksgiving to the divine by performing poojas and homas without any hassle.'
    )
    setSecondPara(
      'Book the services of knowledgeable purohits online, to conduct rituals as prescribed by the vedas and scriptures.'
    )
  }

  const setCookContent = () => {
    setOrangeHeading('What’s your Event?')
    setMainHeading('Book your Cook Today')
    setFirstPara(
      'No event is complete without food offerings to the divine, and prasadam to the guests.'
    )
    setSecondPara(
      'Get healthy, hygienic, and scrumptious traditional meals prepared at your place or catered to your doorstep.'
    )
  }

  const setAstrologyContent = () => {
    setOrangeHeading(`What’s the Occasion?`)
    setMainHeading('Consult your Astrologer')
    setFirstPara(
      'Heavenly bodies guide the universe. Doing the right thing at a right time makes a huge difference when it comes to success.'
    )
    setSecondPara(
      'Let our learned astrologers suggest auspicious dates and times, and guide you in your journey through life.'
    )
  }

  return (
    <div className='hero-section'>
      <div className='swiper-card container'>
        <div className='card-content'>
          <div className='card-text as_banner_detail'>
            <h5 className='as_orange'>{orangeHeading}</h5>
            <h1>{mainHeading}</h1>
            <p>{firstPara}</p>
            <p>{secondPara}</p>
            <Link to='/services' className='as_btn'>
              Book Appointment
            </Link>
          </div>
          <div className='card-image images-group'>
            <img
              className='image-one'
              src={Purohit}
              alt='Purohit'
              onMouseEnter={setPurohitContent}
              
            />
            <img
              className='image-two'
              src={Food}
              alt='Food'
              onMouseEnter={setCookContent}
              
            />
            <img
              className='image-three'
              src={Astrologer}
              alt='Astrologer'
              onMouseEnter={setAstrologyContent}
              
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
