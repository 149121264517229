import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import ServicesCard from '../../components/ServicesCard'

import './Services.css'
import PaurohityaImg from '../../assets/images/services/purohit-services/Shashtipoorthi 2.jpeg'
import SatyaNarayanan from '../../assets/images/services/puja/Satya Narayan Puja.jpg'
import CookImg from '../../assets/images/services/cook/Food.jpeg'
import AstrologerImg from '../../assets/images/services/astrologer-services/Online Consultation.jpg'

const mainServicesList = [
  {
    title: 'Paurohitya',
    shortDescription:
      'Vedic rituals hold an important place in our lives. Conducting them in a pious and prescribed way by itself rejuvenates the surroundings with positive cleansing energy. We have searched and listed some of the most qualified and knowledgeable purohits willing to conduct sacred rituals for you.',
    imgUrls: [PaurohityaImg],
    serviceUrl: 'paurohitya',
  },
  {
    title: 'Pujas',
    shortDescription:
      'Pooja means something in Hinduism, Sanskrit, Hindi. If you want to know the exact meaning, history, etymology or English translation of this term then check out the descriptions on this page.',
    imgUrls: [SatyaNarayanan],
    serviceUrl: 'pujas',
  },
  {
    title: 'Cook',
    shortDescription:
      'We are what we consume, and every sacred ritual is followed by a befitting offering to gods and loved ones and the community. Our collection of chefs offers tailored, quality recipes to suit each specific occasion. No matter the gathering size, they prepare safe, hygienic, and delightful food for your near and dear ones.',
    imgUrls: [CookImg],
    serviceUrl: 'cook',
  },
  {
    title: 'Astrology',
    shortDescription: `The earth, moon and the stars, and all celestial bodies are connected to each other in this universe. An experienced astrologer can decipher the predictable movement of these heavenly bodies and correlate it at a personal level. Find such experts here and let them help you guide you through the “thicks and thins” of life.`,
    imgUrls: [AstrologerImg],
    serviceUrl: 'astrology',
  },
]

export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='Services'>
      <title>Harigurus | Services</title>
      <section className='as_breadcrum_wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h1>Services</h1>
              <ul className='breadcrumb'>
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <Link to={`/services`}>Services</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className='as_blog_wrapper as_padderTop80 as_padderBottom80'>
        <div className='container'>
          <div className=''>
            <div className='col-lg-12'>
              <div className='row'>
                <div className='all-services'>
                  {mainServicesList.map((mainService) => (
                    <ServicesCard
                      key={mainService.title}
                      ctaText='See All Services'
                      {...mainService}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
