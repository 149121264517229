import React from 'react'
// import Header from './Header'
// import Months from '../../sections/home/Months'
// import KnowEvents from '../../sections/home/KnowEvents'


import Testimonials from '../../sections/home/Testimonials'

import HeroSection from '../../sections/home/HeroSection'
import MostPopularEvents from '../../sections/home/MostPopularEvents'
import BookAppointment from '../../sections/home/BookAppointment'
import WhyUs from '../../sections/home/WhyUs'
import RecentEvents from '../../sections/home/RecentEvents'

export default class Home extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className='Home'>
        <title>Harigurus | Leave Your Worries to Us</title>
        <HeroSection />
        <BookAppointment />
        <MostPopularEvents />
        <WhyUs />
        <Testimonials />
        <RecentEvents />
      </div>
    )
  }
}
