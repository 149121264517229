import React from 'react'
import { Link } from 'react-router-dom'

import './PageNotFound.css'

export default function PageNotFound() {
  return (
    <div className='as_error_wrapper'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 text-center'>
            <h1 className='as_margin0'>404</h1>
            <h2 className='as_margin0 as_padderBottom20'>page not found</h2>
            <p className='as_margin0 as_padderBottom30'>
              Sorry. We Can't Seem To Find The Page You're Looking For
            </p>
            <Link to='/' className='as_btn'>
              back to home
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
