import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import PurohitIcon from '../../assets/images/profile/purohit/PurohitProfileIcon.svg'
import CookIcon from '../../assets/images/profile/cook/CookProfileIcon.svg'

import './Profiles.css'

const ProfileCard = (name, brahminType, location, imgUrl) => (
  <div key={name} className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
    <div className='as_team_box text-center'>
      <div className='as_team_img'>
        <img src={imgUrl} alt='' className='img-responsive' />
      </div>
      <h3 className='as_subheading'>{name}</h3>
      <p>{`${brahminType}, ${location}`}</p>
    </div>
  </div>
)

const purohitDetailsList = [
  {
    name: 'Satyaprasannachar',
    mobileNo: '9880135471',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Narasimhachar Kudli',
    mobileNo: '9448171974',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Jeeveshachar',
    mobileNo: '9900036394',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Srinidhi Achar',
    mobileNo: '9972167181',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Raghuveerachar',
    mobileNo: '9164850058',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Aravindachar',
    mobileNo: '7975667671',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Gundachar',
    mobileNo: '9448411278',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Jithamitrachar',
    mobileNo: '9902926166',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Srinivasachar',
    mobileNo: '9448123406',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Raghuachar',
    mobileNo: '9341039480',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Balasubramaniam',
    mobileNo: '9481528672',
    brahminType: 'Smartha',
    location: 'Nanjungud',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Sudharshan Sharma',
    mobileNo: '9980703060',
    brahminType: 'Smartha',
    location: 'Chenraypatna',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Hiran Sharma',
    mobileNo: '9535214870',
    brahminType: 'Smartha',
    location: 'Chenraypatna',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Harivamshachar',
    mobileNo: '9342424578',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Venkatachal H Gudi',
    mobileNo: '9686171756',
    brahminType: 'Madhwa',
    location: 'Shiggon, Haveri',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Girish Govindachar Mannari',
    mobileNo: '9741334708',
    brahminType: 'Madhwa',
    location: 'Kadakol, Savanur, Haveri',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Kanakachala Achar Odeyar',
    mobileNo: '8897366438',
    brahminType: 'Madhwa',
    location: 'Mantralaya',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Pavamanachar(Turahalli)',
    mobileNo: '8050245020',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Harinathachar',
    mobileNo: '9000820203',
    brahminType: 'Madhwa',
    location: 'Ananthpura AP',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Arun Kumar',
    mobileNo: '9964472557',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Manjunath',
    mobileNo: '9880755919',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Chandrashekar',
    mobileNo: '8892198734',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Srinath L',
    mobileNo: '9980332110',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Balasubramaniam S',
    mobileNo: '9740286083',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Vinay S',
    mobileNo: '9845053703',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Subash Murthy',
    mobileNo: '9731473800',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Akshay S',
    mobileNo: '8095441007',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'M V Krishna Kulkarni',
    mobileNo: '8123016731',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'M V Paddu',
    mobileNo: '8050159985',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'PT Jayateerthachar',
    mobileNo: '8088442299',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Uday Simha',
    mobileNo: '9844415010',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Venkatesh',
    mobileNo: '9380288233',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Venu Madhavachar',
    mobileNo: '9449139890',
    brahminType: 'Madhwa',
    location: 'Udupi',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Guru Venkatesha Vittala',
    mobileNo: '9441097255',
    brahminType: 'Madhwa',
    location: 'Ananthapura',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Yogeesh Sharma',
    mobileNo: '9980638854',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Lokesh',
    mobileNo: '9483851145',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Madhusudhan',
    mobileNo: '9845322451',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Ramesh Achar',
    mobileNo: '9164500013',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Prakash GS',
    mobileNo: '9449638091',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Bharath',
    mobileNo: '9449742277',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Rameshachar',
    mobileNo: '9164500013',
    brahminType: 'Madhwa',
    location: 'Mandya',
    description: '',
    imgUrl: PurohitIcon,
  },

  {
    name: 'Vedavyasachar',
    mobileNo: '9341148393',
    brahminType: 'Madhwa',
    location: 'Udupi',
    description: '',
    imgUrl: PurohitIcon,
  },
  {
    name: 'Rishikeshachar',
    mobileNo: '9880958074',
    brahminType: 'Madhwa',
    location: 'Udupi',
    description: '',
    imgUrl: PurohitIcon,
  },

  {
    name: 'Sridhar Vadya',
    mobileNo: '9986375062',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },

  {
    name: 'Ashwath A S',
    mobileNo: '9448316939',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },

  {
    name: 'Ramaswamy K L',
    mobileNo: '9538970007',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },

  {
    name: 'Kambalur Pavamanachar',
    mobileNo: '9980143820',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },

  {
    name: 'Pavangiri Sharma',
    mobileNo: '9036152224',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: PurohitIcon,
  },
]

const cookDetailsList = [
  {
    name: 'Gopalkrishna N',
    mobileNo: '9964472557',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'D V Ravikumar',
    mobileNo: '9880933890',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Girish D R',
    mobileNo: '9845217375',
    brahminType: 'Smartha',
    location: 'Bangalore',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Dinesh',
    mobileNo: '8073051542',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Dwarka',
    mobileNo: '9060497299',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Satish',
    mobileNo: '6361057332',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Shrinivas',
    mobileNo: '8296865958',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Suryanayanarao',
    mobileNo: '9945128534',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Guruprasad',
    mobileNo: '9964388337',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Thirumal Rao',
    mobileNo: '9591513776',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Ravi',
    mobileNo: '9845296406',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Umesh',
    mobileNo: '9880138766',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Girish',
    mobileNo: '9110264635',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Suresh',
    mobileNo: '8884985504',
    brahminType: 'Madhwa',
    location: 'Tumkur',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Raghu',
    mobileNo: '9448782353',
    brahminType: 'Madhwa',
    location: 'Shimoga',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Guru Venkatesha Vittala',
    mobileNo: '9441097255',
    brahminType: 'Madhwa',
    location: 'Ananthapura',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Vijayeendra',
    mobileNo: '9448857098',
    brahminType: 'Madhwa',
    location: 'Bhadravathi',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Gopalkrishna',
    mobileNo: '7026021972',
    brahminType: 'Madhwa',
    location: 'Ballary',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Yoga Narasimha',
    mobileNo: '9945004636',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Seetaram',
    mobileNo: '6360938103',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: CookIcon,
  },
  {
    name: 'Hayavadan Achar',
    mobileNo: '9449722493',
    brahminType: 'Madhwa',
    location: 'Bangalore',
    description: '',
    imgUrl: CookIcon,
  },
]

const Profile = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='Profile'>
    <title>Harigurus | Profiles</title>
      <section className='as_breadcrum_wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h1>Profiles</h1>
              <ul className='breadcrumb'>
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <Link to={`/profiles`}>Profiles</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className='as_team_wrapper as_padderTop80 as_padderBottom50'>
        <div className='container'>
          <details open>
            <summary>Purohits</summary>

            <div className='row'>
              {purohitDetailsList.map(
                ({ name, brahminType, location, imgUrl }) =>
                  ProfileCard(name, brahminType, location, imgUrl)
              )}
            </div>
          </details>
        </div>
        <div className='container'>
          <details open>
            <summary>Cooks</summary>

            <div className='row'>
              {cookDetailsList.map(({ name, brahminType, location, imgUrl }) =>
                ProfileCard(name, brahminType, location, imgUrl)
              )}
            </div>
          </details>
        </div>
        <div className='container'>
          <details open>
            <summary>Astrologer</summary>

            {purohitDetailsList.map(({ name, brahminType, location, imgUrl }) =>
              ProfileCard(name, brahminType, location, imgUrl)
            )}
          </details>
        </div>
      </section>
    </div>
  )
}

export default Profile
