import React, { useEffect, useState } from 'react'
import { NavLink, Link, useHistory } from 'react-router-dom'

import Popup from '../components/Popup/Popup'
import { useContext } from 'react'
import { AuthContext } from '../context/AuthContext'
import { UserContext } from '../context/UserContext'

import NewLogo from '../assets/images/svg/newLogo.png'
import UserPlus from '../assets/images/svg/user-plus.svg'

import './Header.css'

const Header = () => {
  const [popupOpen, setPopupOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [token, setToken] = useContext(AuthContext)
  const [user] = useContext(UserContext)

  const toggleMenuOpenClass = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  }, [isMenuOpen])

  const toggleActiveClass = (e) => {
    if (e.target.nextElementSibling) {
      e.target.parentElement.classList.toggle('active')
      e.target.nextElementSibling.classList.toggle('active')
    } else if (window.innerWidth < 768) {
      toggleMenuOpenClass()
    }
  }

  const openModel = () => {
    document.body.classList.add('modal-open')
    setPopupOpen(true)
  }

  const history = useHistory()

  const logout = () => {
    setToken(null)
    localStorage.removeItem('token')
    history.push('/')
  }

  const redirectToUserPage = () => {
    history.push('/user/profile')
  }

  const displayUsername = () => {
    if (window.innerWidth > 768) return user.name.split(' ')[0]

    return user.name
  }

  const getCurrentUrl = (location) => location

  return (
    <section className='as_header_wrapper'>
      <Popup
        popupOpen={popupOpen}
        setPopupOpen={setPopupOpen}
        token={token}
        setToken={setToken}
      />
      <div className='logo-section'>
        <div className='as_logo'>
          <NavLink to='/'>
            <img src={NewLogo} alt='logo' />
          </NavLink>
        </div>

        <ul className='call-us'>
          <li>
            <a href='tel: +91 9353066802'>
              <div className='as_infobox'>
                <span className='as_infoicon'>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/images/svg/headphone.svg'
                    }
                    alt=''
                  />
                </span>

                <span className='as_orange call-us-text'>Call Us:</span>
                <span className='mobile-number'>&nbsp;9353066802</span>
              </div>
            </a>
            <span onClick={toggleMenuOpenClass} className='as_toggle'>
              <img
                src={process.env.PUBLIC_URL + '/assets/images/svg/menu.svg'}
                alt=''
              />
            </span>
          </li>
        </ul>
      </div>

      <div className='as_header_detail'>
        <div className={`as_menu_wrapper ${isMenuOpen ? 'menu_open' : ''}`}>
          <div className='as_menu'>
            <ul className='nav-menu'>
              <li>
                <NavLink
                  onClick={toggleActiveClass}
                  exact
                  to='/'
                  activeClassName='active'
                >
                  home
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={toggleActiveClass}
                  exact
                  to='/about'
                  activeClassName='active'
                >
                  about
                </NavLink>
              </li>

              <li className='nav-services as_submenu_li'>
                <NavLink
                  onClick={toggleActiveClass}
                  to='/services'
                  activeClassName='active'
                >
                  services
                </NavLink>
                <ul className='as_submenu'>
                  <li className='as_submenu_li sub_services'>
                    <Link onClick={toggleActiveClass} to='/services/paurohitya'>
                      Paurohitya
                    </Link>
                    <ul className='as_submenu'>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/paurohitya/aksharabhyasa'
                        >
                          Aksharabhyasa
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/paurohitya/mundan'
                        >
                          Mundan
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/paurohitya/namakarna'
                        >
                          Namakarna
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/paurohitya/upanayana'
                        >
                          Upanayana
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/paurohitya/engagement'
                        >
                          Engagement
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/paurohitya/wedding'
                        >
                          Wedding
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/paurohitya/shashtipoorthiShanti'
                        >
                          Shashtipoorthi Shanti
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/paurohitya/bhimarathaShanti'
                        >
                          Bhimaratha Shanti
                        </Link>
                      </li>

                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/paurohitya/gruhapravesha'
                        >
                          Gruhapravesha
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/paurohitya/pitruPaksha'
                        >
                          Pitru Paksha
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/paurohitya/shraddha'
                        >
                          Shraddha
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/paurohitya/uttaraKriyaKarma'
                        >
                          Uttara Kriya Karma
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className='as_submenu_li sub_services'>
                    <Link onClick={toggleActiveClass} to='/services/pujas'>
                      Pujas
                    </Link>
                    <ul className='as_submenu'>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/pujas/satyanarayanaPuja'
                        >
                          Satyanarayana Puja
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/pujas/lakshmiPuja'
                        >
                          Lakshmi Puja
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/pujas/officePuja'
                        >
                          Office Puja
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className='as_submenu_li sub_services'>
                    <Link onClick={toggleActiveClass} to='/services/cook'>
                      Cook
                    </Link>
                    <ul className='as_submenu'>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/cook/inHouseCooking'
                        >
                          In-House cooking
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/cook/catering'
                        >
                          Catering
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className='as_submenu_li sub_services'>
                    <Link onClick={toggleActiveClass} to='/services/astrology'>
                      Astrology
                    </Link>
                    <ul className='as_submenu'>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/astrology/onlineConsultation'
                        >
                          Online Consultation
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/astrology/bookAndVisit'
                        >
                          Book & Visit
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleActiveClass}
                          to='/services/astrology/qAndA'
                        >
                          Q & A
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  onClick={toggleActiveClass}
                  exact
                  to='/profiles'
                  activeClassName='active'
                >
                  Profiles
                </NavLink>
              </li>
              <li>
                <NavLink onClick={toggleActiveClass} exact to='/contact'>
                  contact
                </NavLink>
              </li>
              <li
                className='as_submenu_li profile-link-container'
                onClick={toggleActiveClass}
              >
                <Link
                  className='profile-link'
                  onClick={token ? redirectToUserPage : openModel}
                  to={getCurrentUrl}
                >
                  <div className='as_search_wrapper'>
                    <div className='search-icon-container'>
                      <img
                        src={token ? user.imageUrl : UserPlus}
                        alt=''
                        className='as_search'
                      />
                    </div>
                  </div>
                  <p className='username'>
                    {token ? displayUsername() : 'Sign In'}
                  </p>
                </Link>
                {token && (
                  <ul className='as_submenu'>
                    <li className='as_submenu_li sub_services'>
                      <Link onClick={toggleActiveClass} to='/user/profile'>
                        Account Settings
                      </Link>
                    </li>
                    <li className='as_submenu_li sub_services'>
                      <Link onClick={toggleActiveClass} to='/user/orders'>
                        Orders
                      </Link>
                    </li>
                    <li className='as_submenu_li sub_services'>
                      <Link
                        to='/'
                        onClick={logout}
                        className='as_link logout-btn'
                      >
                        Log Out
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        onClick={toggleMenuOpenClass}
        className={`overlay ${isMenuOpen ? 'active' : ''}`}
      ></div>
    </section>
  )
}

export default Header
