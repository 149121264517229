import React from 'react'
import { Link } from 'react-router-dom'

import Aksharabhyasa from '../../assets/images/services/purohit-services/Aksharabhyasa.jpg'
import Mundan from '../../assets/images/services/purohit-services/Mundan.jpg'
import Namakarna from '../../assets/images/services/purohit-services/Namakarna.jpg'
import UttaraKriyaKarma from '../../assets/images/services/purohit-services/Uttara Kriya Karma 2.jpg'

import './RecentEvents.css'

export default function RecentEvents() {
  return (
    <section className='RecentEvents as_product_wrapper as_padderBottom80 as_padderTop40'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 text-center'>
            <h1 className='as_heading as_heading_center'>Recent Events</h1>
            <p className='as_font14 as_margin0 as_padderBottom20'>
              HariGurus continues to be the preferred place for our users to
              find purohits and cooks who make their
              <br /> events a grand success. See our recent bookings.
            </p>
            <div className='row as_product_slider'>
              <div className='col-lg-3 col-md-6'>
                <div className='as_product_box'>
                  <div className='as_product_img'>
                    <img
                      src={Aksharabhyasa}
                      alt=''
                      className='img-responsive'
                    />
                    <Link to='/services/paurohitya/aksharabhyasa'>
                      <ul>
                        <li>
                          <span>View Details</span>
                        </li>
                      </ul>
                    </Link>
                  </div>
                  <span><img className="rating-img" src="assets/images/rating.png" alt="" /></span>
                  <h4 className='as_subheading'>Aksharabhyasa</h4>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div className='as_product_box'>
                  <div className='as_product_img'>
                  
                    <img src={Mundan} alt='' className='img-responsive' />
                    <Link to='/services/paurohitya/mundan'>
                      <ul>
                        <li>
                          <span>View Details</span>
                        </li>
                      </ul>
                    </Link>
                  </div>
                  <span><img className="rating-img" src="assets/images/rating.png" alt="" /></span>
                  <h4 className='as_subheading'>Mundan</h4>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div className='as_product_box'>
                  <div className='as_product_img'>
                    <img src={Namakarna} alt='' className='img-responsive' />
                    <Link to='/services/paurohitya/namakarna'>
                      <ul>
                        <li>
                          <span>View Details</span>
                        </li>
                      </ul>
                    </Link>
                  </div>
                  <span><img className="rating-img" src="assets/images/rating.png" alt="" /></span>
                  <h4 className='as_subheading'>Namakarana</h4>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div className='as_product_box'>
                  <div className='as_product_img'>
                    <img src={UttaraKriyaKarma} alt='' className='img-responsive' />
                    <Link to='/services/paurohitya/uttaraKriyaKarma'>
                      <ul>
                        <li>
                          <span>View Details</span>
                        </li>
                      </ul>
                    </Link>
                  </div>
                  <span><img className="rating-img" src="assets/images/rating.png" alt="" /></span>
                  <h4 className='as_subheading'>Uttara Kriya Karma</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
