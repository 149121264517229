import React from 'react'
import { Link } from 'react-router-dom'

// import Logo from '../assets/images/svg/logo.svg'
import NewLogo from '../assets/images/svg/newLogo.png'
import './Footer.css'

export default function Footer() {
  return (
    <section className='as_footer_wrapper as_padderTop30'>
      <div className='container'>
        <div className='row'>
          <div className='as_newsletter_wrapper as_verticle_center as_padderBottom60'>
            <div className='col-lg-5 col-md-6 col-sm-12'>
              <h1 className='as_heading'>Our Newsletter</h1>
              <p>
                Get Your Daily Horoscope, Daily Lovescope and Daily
                <br /> Tarot Directly In Your Inbox
              </p>
            </div>
            <div className='col-lg-7 col-md-6 col-sm-12'>
              <div className='as_newsletter_box'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter your Email Here...'
                />
                <a href='/' className='as_btn'>
                  subscribe now
                </a>
              </div>
            </div>
          </div>

          <div className='as_footer_inner as_padderTop50 as_padderBottom80'>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='as_footer_widget'>
                <div className='as_footer_logo'>
                  <a href='index.html'>
                    <img src={NewLogo} alt='' />
                  </a>
                </div>
                <p>
                  Following your customs and traditions is not difficult
                  anymore. HariGurus is a one-stop-shop for all Hindu religious,
                  customs and traditional requirements.
                </p>
                <div className='as_share_box'>
                  <p>Follow Us</p>
                  <ul>
                    <li>
                      <a
                        href='https://www.facebook.com/harigurus2021'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/svg/facebook.svg'
                          }
                          alt=''
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://instagram.com/hari_gurus?utm_medium=copy_link'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/svg/instagram-brands.svg'
                          }
                          alt=''
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.youtube.com/channel/UCa1qcU6MURrjTjXSjHjxJrg'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/svg/youtube.svg'
                          }
                          alt=''
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='as_footer_widget'>
                <h3 className='as_footer_heading'>Quick Links</h3>
                <ul>
                  <li>
                    <Link to='/'>
                      <span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          xmlnsXlink='http://www.w3.org/1999/xlink'
                          preserveAspectRatio='xMidYMid'
                          width={8}
                          height={12}
                          viewBox='0 0 8 12'
                        >
                          <path
                            d='M8.000,5.998 L-0.002,11.997 L1.292,5.998 L-0.002,-0.001 L8.000,5.998 ZM1.265,9.924 L6.502,5.998 L1.265,2.071 L2.112,5.998 L1.265,9.924 ZM5.451,5.998 L2.496,8.213 L2.974,5.998 L2.496,3.783 L5.451,5.998 Z'
                            className='cls-1'
                          />
                        </svg>
                      </span>{' '}
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to='/about'>
                      <span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          xmlnsXlink='http://www.w3.org/1999/xlink'
                          preserveAspectRatio='xMidYMid'
                          width={8}
                          height={12}
                          viewBox='0 0 8 12'
                        >
                          <path
                            d='M8.000,5.998 L-0.002,11.997 L1.292,5.998 L-0.002,-0.001 L8.000,5.998 ZM1.265,9.924 L6.502,5.998 L1.265,2.071 L2.112,5.998 L1.265,9.924 ZM5.451,5.998 L2.496,8.213 L2.974,5.998 L2.496,3.783 L5.451,5.998 Z'
                            className='cls-1'
                          />
                        </svg>
                      </span>{' '}
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to='/services'>
                      <span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          xmlnsXlink='http://www.w3.org/1999/xlink'
                          preserveAspectRatio='xMidYMid'
                          width={8}
                          height={12}
                          viewBox='0 0 8 12'
                        >
                          <path
                            d='M8.000,5.998 L-0.002,11.997 L1.292,5.998 L-0.002,-0.001 L8.000,5.998 ZM1.265,9.924 L6.502,5.998 L1.265,2.071 L2.112,5.998 L1.265,9.924 ZM5.451,5.998 L2.496,8.213 L2.974,5.998 L2.496,3.783 L5.451,5.998 Z'
                            className='cls-1'
                          />
                        </svg>
                      </span>{' '}
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to='/profiles'>
                      <span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          xmlnsXlink='http://www.w3.org/1999/xlink'
                          preserveAspectRatio='xMidYMid'
                          width={8}
                          height={12}
                          viewBox='0 0 8 12'
                        >
                          <path
                            d='M8.000,5.998 L-0.002,11.997 L1.292,5.998 L-0.002,-0.001 L8.000,5.998 ZM1.265,9.924 L6.502,5.998 L1.265,2.071 L2.112,5.998 L1.265,9.924 ZM5.451,5.998 L2.496,8.213 L2.974,5.998 L2.496,3.783 L5.451,5.998 Z'
                            className='cls-1'
                          />
                        </svg>
                      </span>{' '}
                      Profiles
                    </Link>
                  </li>
                  <li>
                    <Link to='/contact'>
                      <span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          xmlnsXlink='http://www.w3.org/1999/xlink'
                          preserveAspectRatio='xMidYMid'
                          width={8}
                          height={12}
                          viewBox='0 0 8 12'
                        >
                          <path
                            d='M8.000,5.998 L-0.002,11.997 L1.292,5.998 L-0.002,-0.001 L8.000,5.998 ZM1.265,9.924 L6.502,5.998 L1.265,2.071 L2.112,5.998 L1.265,9.924 ZM5.451,5.998 L2.496,8.213 L2.974,5.998 L2.496,3.783 L5.451,5.998 Z'
                            className='cls-1'
                          />
                        </svg>
                      </span>{' '}
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='as_footer_widget'>
                <h3 className='as_footer_heading'>Contact Us</h3>
                <ul className='as_contact_list'>
                  <li>
                    <img
                      src={
                        process.env.PUBLIC_URL + '/assets/images/svg/map.svg'
                      }
                      alt=''
                    />
                    <p>
                      Block A A-804, Century Central, Kanakapura Rd, Mango
                      Garden Layout, Bikasipura, Bengaluru, 560078
                    </p>
                  </li>
                  <li>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/svg/address.svg'
                      }
                      alt=''
                    />
                    <p>
                      <a
                        href='mailto: harigurus@gmail.com'
                        rel='noreferrer'
                        target='_blank'
                      >
                        harigurus2021@gmail.com
                      </a>
                    </p>
                  </li>
                  <li>
                    <img
                      src={
                        process.env.PUBLIC_URL + '/assets/images/svg/call.svg'
                      }
                      alt=''
                    />
                    <p>
                      <a href='tel: +91 9353066802'>+91 9353066802</a>
                      <br />
                      <a href='tel: +91 8079687199'>+91 8079687199</a>
                      <br />
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
