import Aksharabhyasa from '../assets/images/services/purohit-services/Aksharabhyasa.jpg'
import Mundan from '../assets/images/services/purohit-services/Mundan.jpg'
import Namakarna from '../assets/images/services/purohit-services/Namakarna.jpg'
import Upanayana from '../assets/images/services/purohit-services/Upanayana.jpg'
import Wedding from '../assets/images/services/purohit-services/Wedding.jpg'
import Engagement from '../assets/images/services/purohit-services/Engagement.jpg'
import BhimarathiShanthi from '../assets/images/services/purohit-services/Bhimarathi Shanthi 1.jpeg'
import Shashtipoorthi from '../assets/images/services/purohit-services/Sastiapoorthi 1.jpeg'
import Gruhapravesha from '../assets/images/services/purohit-services/Gruhapravesha.jpg'
import PitruPaksha from '../assets/images/services/purohit-services/Pitru Paksha.jpg'
import Shraddha from '../assets/images/services/purohit-services/Shraddha.jpg'
import UttaraKriyaKarma from '../assets/images/services/purohit-services/Uttara Kriya Karma.jpg'

import SatyaNarayanan from '../assets/images/services/puja/Satya Narayan Puja.jpg'
import LakshmiPuja from '../assets/images/services/puja/Lakshmi Pooja.jpg'
import OfficePuja from '../assets/images/services/puja/Office Puja.jpg'

import InHouseCooking from '../assets/images/services/cook/Food.jpeg'
import Catering from '../assets/images/services/cook/Catering.png'

import OnlineConsultation from '../assets/images/services/astrologer-services/Online Consultation.jpg'
import BookAndVisit from '../assets/images/services/astrologer-services/Book & Visit.jpg'
import QAndA from '../assets/images/services/astrologer-services/Q & A.jpg'

export const PurohitServicesList = [
  {
    title: 'Aksharabhyasa',
    description:
      'A ritual marking the beginning of a child’s formal education, Aksharabhyasa seeks the blessings of Saraswati – the goddess of knowledge, art, wisdom and learning, on the child.',
    imgUrl: Aksharabhyasa,
    serviceUrl: 'paurohitya/aksharabhyasa',
  },
  {
    title: 'Mundan',
    description:
      'It is an auspicious ritual where the child’s hair is shaved for the first time to cleanse its soul of all the sins from past birth. Rooted in the belief that the eternal atman (soul) seeks rebirth until salvation, Mundan signifies a fresh start of a new life.',
    imgUrl: Mundan,
    serviceUrl: 'paurohitya/mundan',
    price: 1000,
  },
  {
    title: 'Namakarna',
    description:
      'Another important ritual, Namakarana is a ceremony in which the newborn is given an identity and introduced to the world. The name is carefully chosen based on the nakshatras and in accordance with the lineage of the family to which the child is born.',
    imgUrl: Namakarna,
    serviceUrl: 'paurohitya/namakarna',
    price: 2000,
  },
  {
    title: 'Upanayana',
    description:
      'A sacred ritual that marks the beginning of an adolescent’s spiritual journey, the Upanayana ceremony is accompanied by recital of Gayatri mantra in the presence of a guru and concludes with the child offering guru dakshina.',
    imgUrl: Upanayana,
    serviceUrl: 'paurohitya/upanayana',
    price: 2000,
  },

  {
    title: 'Engagement',
    description:
      'A prelude to wedding, the engagement ceremony signifies a formal commitment by the prospective bride and the groom, in the presence of their parents, to marry each other. It is conducted by invoking the blessings of gods, exchange of gifts and choosing an auspicious day for the wedding under the guidance of a learned purohit.',
    imgUrl: Engagement,
    serviceUrl: 'paurohitya/engagement',
    price: 7000,
  },
  {
    title: 'Wedding',
    description:
      'A Hindu wedding is not just a grand celebration but a combination of various rituals and poojas performed in a particular order. Each ritual has its own significance, and the ceremony culminates with the bride and the groom exchanging vows over seven sacred steps (Saptapadi) in front of Agni as witness.',
    imgUrl: Wedding,
    serviceUrl: 'paurohitya/wedding',
    price: 10000,
  },

  {
    title: 'Shashtipoorthi Shanti',
    description:
      'The Shashtipoorthi Shanti is a ritual conducted when a Hindu male attains the age of 60. The ceremony marks the preparation of a person to the impending old age and seeks the blessing of gods to bestow good health and a long life. In this ceremony, the couple also renew their wedding vows, committing to stand by each other during this new phase of life.',
    imgUrl: Shashtipoorthi,
    serviceUrl: 'paurohitya/shashtipoorthiShanti',
  },

  {
    title: 'Bhimaratha Shanti',
    description:
      'A ceremony that marks the husband in married relationship reaching the age of 70, Bhima Ratha Shanti brings all the family members together to conduct a series of poojas and homas to seek divine blessings for a long, healthy and happy marital life ahead. The ceremony cleanses the person of any bad karma or sins committed during the lifetime and aids in spiritual well-being.',
    imgUrl: BhimarathiShanthi,
    serviceUrl: 'paurohitya/bhimarathaShanti',
  },

  {
    title: 'Gruhapravesha',
    description:
      'A cleansing ritual conducted whenever someone moves to a new house, Gruhapravesha involves a series of poojas performed on an auspicious day to cleanse the dwelling of any negative energy and seek the blessings of good fortune, love, happiness, and fulfilment from the deities.',
    imgUrl: Gruhapravesha,
    serviceUrl: 'paurohitya/gruhapravesha',
  },
  {
    title: 'Pitru Paksha',
    description:
      'Observed over a fortnight during the Mahalaya lunar phase, Pitru Paksha is considered a period when the ancestors visit their descendants. During this period, poojas and shraddha are performed as an act of remembrance and thanksgiving for their influence and blessings bestowed upon the present and future generations.',
    imgUrl: PitruPaksha,
    serviceUrl: 'paurohitya/pitruPaksha',
  },
  {
    title: 'Shraddha',
    description:
      'A ceremony of remembrance and offerings to the departed family members, Shraddha is performed to bring peace to their souls. The rituals are carried out every year, preferably on the day their soul departed from earth as per the lunar calendar. The poojas are accompanied by an offering of food to appease their soul and receive blessings in return.',
    imgUrl: Shraddha,
    serviceUrl: 'paurohitya/shraddha',
  },
  {
    title: 'Uttara Kriya Karma',
    description:
      'A set of rituals performed over a period of 14 days following a person’s demise. Starting with the preparation for cremation, the rituals address various processes that need to be fulfilled in order for the deceased’s soul to attain peace. These vedic rituals also help the bereaved cope with the loss of their dear ones. ',
    imgUrl: UttaraKriyaKarma,
    serviceUrl: 'paurohitya/uttaraKriyaKarma',
  },
]

export const PoojaServicesList = [
  {
    title: 'Satyanarayana Puja',
    description:
      'Performing Satyanarayana Pooja places one in the good graces of God Vishnu. It is an important part of most Hindu ceremonies and brings the blessings of good health, wealth, happiness and alleviates all the troubles.',
    imgUrl: SatyaNarayanan,
    serviceUrl: 'pujas/satyanarayanaPuja',
  },
  {
    title: 'Lakshmi Puja',
    description:
      'Also known as Mahalakshmi pooja, the ritual is performed seeking the blessings of the goddess of wealth and prosperity. The pooja also signifies thanksgiving for all the good fortunes bestowed by her grace.',
    imgUrl: LakshmiPuja,
    serviceUrl: 'pujas/lakshmiPuja',
  },
  {
    title: 'Office Puja',
    description:
      'Offices are places of business that support the livelihood of employers and employees. To prosper and conduct the business without obstacles, it is a customary practice to seek divine blessings. The pooja includes invocation of gods Ganapathi, Lakshmi and the Navagrahas.',
    imgUrl: OfficePuja,
    serviceUrl: 'pujas/officePuja',
  },
]
export const CookServicesList = [
  {
    title: 'In-house Cooking',
    description:
      'Our curated list includes some of the best cooks known to prepare quality food for any function in any quantity. They will prepare the food at your home or the function venue and serve it to the table.',
    imgUrl: InHouseCooking,
    serviceUrl: 'cook/inHouseCooking',
  },
  {
    title: 'Catering',
    description:
      'Our list of professional caterers have years of experience preparing and serving traditional food for all kinds of religious events. Select the caterer and order food based on the type of function or customize your selection based on various available options.',
    imgUrl: Catering,
    serviceUrl: 'cook/catering',
  },
]

export const AstrologerServicesList = [
  {
    title: 'Online Consultation',
    description:
      'You can now avail the services of some of the top notch astrologers from the comfort of your home or office. Book a slot with any of the listed astrologers to gain insights into your past, present and future. You can seek their astrological and spiritual guidance on things of importance and plan accordingly.',
    imgUrl: OnlineConsultation,
    serviceUrl: 'astrologer/onlineConsultation',
    ctaText: 'Book Now',
  },
  {
    title: 'Book & Visit',
    description:
      'Browse through our list of qualified astrologers along with their expertise and location to find those who meet your requirements. Once you have found what you are looking for, book an appointment and visit them.',
    imgUrl: BookAndVisit,
    serviceUrl: 'astrologer/bookAndVisit',
    ctaText: 'Book Now',
  },
  {
    title: 'Q & A',
    description:
      'Do you have any questions that are bothering you, or curiosity that is making you restless? You can ask them on HariGurus and get relevant answers from qualified astrologers. This service is ideal for quick questions and clarifications. If you want more personalized guidance, we suggest you opt for online consultation or book an appointment for a personal visit.',
    imgUrl: QAndA,
    serviceUrl: 'astrologer/qAndA',
    ctaText: 'Book Now',
  },
]

export const serviceDetailsList = {
  paurohitya: {
    aksharabhyasa: {
      title: 'Aksharabhyasa',
      description: `<p>Aksharabhyasa is one of the sixteen important Vedic rites of life, referred to as "samskaras''. It is generally conducted when a child is two to five years old, and the ritual marks the beginning of the child's formal education. During the ceremony, the parents under the guidance of a learned purohit perform Ganapati and Saraswati pooja to ensure the child is blessed by the revered gods to have a smooth and enriching learning experience throughout the life, devoid of any obstacles.</p>
      <p>After the pooja, the parents (commonly the father or any other elder male family member) make the child write the letter ॐ with its index finger on a plate of rice. It is then followed by the names of gods and alphabets of their preferred language.</p>
      <p>The ceremony is followed by the distribution of prasadam among those in attendance.
        In addition to Ganapati and Saraswati pooja, praying to Hayagriva – an avatar of Vishnu also known as the god of knowledge is considered auspicious for the child’s prospects.
        The Aksharabhyasa ceremony can be a simple or an elaborate affair depending on the preferences of the parents.</p> 
        `,
      shortDescription:
        'A ritual marking the beginning of a child’s formal education, Aksharabhyasa seeks the blessings of Saraswati – the goddess of knowledge, art, wisdom and learning, on the child.',
      imgUrls: [
        Aksharabhyasa,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      helpDetails: `
      <p>On HariGurus, we list purohits who are well versed in Vedic rituals. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services for your child’s Aksharabhyasa on a particular date and time. If you are unsure about the auspicious date and time, our list of astrologers will help.</p>

      <p>The purohits will give you a detailed description of the rituals and make necessary preparation, including the necessary pooja material to conduct the ceremony. They will arrive at your location on the preferred date and time and conduct the rituals as prescribed by sacred texts. </p>
      `,
      priceWithArticles: '5,500',
      priceWithoutArticles: '2,750',
    },
    mundan: {
      title: 'Mundan',
      description: `
        <p>According to the Vedic literature, Mundan, also known as Chudakarana is the eighth of sixteen samskaras. The Mundan ritual is generally conducted when the child is one year old. However, depending on the culture and the preferences, the ceremony can be conducted in any odd year until the child attains the age of seven.</p>
        <p>The Mundan ritual signifies the elimination of any sin or bad karma associated with the child from its past life. It will allow the child’s soul to start its journey in the new life in pursuit of its own destiny without the negative influences associated with the previous births.</p>
        <p>In this ritual, the child’s hair from birth is shaved for the first time on an auspicious date and time under the supervision of a purohit. The purohit performs a pooja or a homa with the child seated in front on its mother’s lap to invoke the blessings of the gods. Following the pooja, the purohit will either cut a portion of hair or shave the child’s head leaving a small tuft of hair while chanting the sacred mundan samskara mantra. However, it is also common for a professional barber to shave the child’s head once the purohit completes the shastra by symbolically cutting a small portion of the hair during pooja.</p>
        <p>The shaven head of the child is then washed with holy water and a paste of sandalwood and turmeric is applied. The hair removed during the ceremony is then disposed of as per the purohit’s advice.</p>
       
        `,
      shortDescription: `
        It is an auspicious ritual where the child’s hair is shaved for the first time to cleanse its soul of all the sins from past birth. Rooted in the belief that the eternal atman (soul) seeks rebirth until salvation, Mundan signifies a fresh start of a new life.`,
      imgUrls: [
        Mundan,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      helpDetails: `
      <p>On HariGurus, we list purohits who are well versed in Vedic rituals. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services for your child’s Mundan on a particular date and time. If you are unsure about the auspicious date and time, our list of astrologers will help.</p>
      <p>The purohits will give you a detailed description of the rituals and make necessary preparation, including the necessary pooja material to conduct the ceremony. They will arrive at your location on the preferred date and time and conduct the rituals as prescribed by sacred texts. </p>
      `,
      priceWithArticles: '3,300',
      priceWithoutArticles: '1,100',
    },
    namakarna: {
      title: 'Namakarna',
      description: `
      <p>Namakarana is the fourth of the sixteen samskaras which is followed while naming a newborn. The ritual is conducted on the 11th day of the child’s birth or on any auspicious day until the child’s first birthday. Namakarana is the ceremonial naming of the child in a prescribed format as per the Hindu traditions.</p>
      <p>The Namakarana ritual is influenced by Vedic astrology and carried out amid poojas offering salutations to the gods, ancestors and then Navagraha homa. The ceremony is generally performed during the first half of the day and the child’s name is chosen based on the family deity, the astrological date and time of the child’s birth and the preference of parents and family members.</p>
      <p>A traditional Hindu name consists of four parts, each signifying one of the qualities imbibed in the child by way of its birth into the family. The first name, “Kuladaiva Nama” refers to the family deity, then “Masa Nama” representing the lunar month in which the child was born, then the “Nakshatra Nama” based on the star under which the child is born and finally the “Vyavahara Nama” which is the preferred name. It is a common practice to use “Vyavahara Nama” as the first name in day-to-day life. The Namakarana ceremony bestows the child with an identity which it will use until the end and remembered for long in the future.</p>
      <p>The Namakarana rituals will be conducted by a learned purohit with in-depth knowledge of Vedic astrology, who will suggest appropriate names based on the child’s horoscope. The child, accompanied by its parents, participates in the pooja conducted in front of a kalasha placed on a plate of rice. All the four names of the child are written on rice using a dried turmeric root. Following the pooja, offerings are given to the purohits, and the chosen name is whispered into the child’s right ear three times by the parents.</p>
      <p>The Namakarana rituals are concluded by distributing prasadam to the participating family and friends.
      </p>
      `,
      shortDescription: `
      Another important ritual, Namakarana is a ceremony in which the newborn is given an identity and introduced to the world. The name is carefully chosen based on the nakshatras and in accordance with the lineage of the family to which the child is born.
      `,
      imgUrls: [
        Namakarna,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      helpDetails: `
      <p>On HariGurus, we list purohits who are well versed in Vedic rituals. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services for your child’s Namakarana on a particular date and time. If you are unsure about the auspicious date and time, our list of astrologers will help.</p>
      <p>The purohits will give you a detailed description of the rituals and make necessary preparation, including the necessary pooja material to conduct the ceremony. They will arrive at your location on the preferred date and time and conduct the rituals as prescribed by sacred texts. </p>
     
      `,
      priceWithArticles: '13,200',
      priceWithoutArticles: '7,700',
    },
    upanayana: {
      title: 'Upanayana',
      description: `
      <p>Upanayana is the tenth of sixteen samskaras which marks the initiation of a child’s spiritual journey through life. The ritual signifies the preparedness of a child through adolescence to attain Vedic knowledge and create their own spiritual identity. As a part of the ritual, the child becomes a ward of a spiritual teacher and receives a three-stranded thread to be always worn over the left shoulder, marking his spiritual birth.</p>
      <p>The Upanayana ceremony consists of multiple Vedic rituals which are conducted on an auspicious day chosen based on the child’s nakshatra. These rituals symbolize that the child is prepared to leave the comforts of home and care of their parents to pursue knowledge. The initiation is presided by a learned purohit who starts the ritual by invoking the blessings of gods and ancestors. The child is then fed by their mother, which will be followed by a homa or a kalasha pooja. After the pooja, the child receives the sacred thread from the father who places it across the left shoulder as per the purohit’s instructions. The three strands of the thread signify the Hindu Trinity of Shiva, Vishnu and Brahma while serving as a reminder of the child’s debt to the teacher, parents, and God.  It is followed by Suryadarshanam and recital of Gayatri Mantra.</p>
      <p>The investiture ritual concludes with the child symbolically seeking alms from his parents as a mark of humility and complete submission to the pursuit of knowledge. The ceremony concludes with the child receiving the blessings of elders and performing Sandhyavandanam.</p>
      <p>Upanayana is an elaborate ceremony, and the guests are treated with a traditional feast in the end.</p>
      `,
      shortDescription: `
      A sacred ritual that marks the beginning of an adolescent’s spiritual journey, the Upanayana ceremony is accompanied by recital of Gayatri mantra in the presence of a guru and concludes with the child offering guru dakshina.
      `,
      imgUrls: [
        Upanayana,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      helpDetails: `
      <p>On HariGurus, we list purohits who are well versed in Vedic rituals. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services for your child’s Upanayana on a particular date and time. If you are unsure about the auspicious date and time, our list of astrologers will help.</p>
      <p>The purohits will give you a detailed description of the rituals and make necessary preparation, including the necessary pooja material to conduct the ceremony. They will arrive at your location on the preferred date and time and conduct the rituals as prescribed by sacred texts. </p>
      
      `,
      priceWithArticles: '22,000',
      priceWithoutArticles: '11,000',
    },
    engagement: {
      title: 'Engagement',
      description: `
      <p>An Engagement is a prelude to the wedding and is a significant ceremony in the Hindu tradition. It brings together not only the future bride and groom, but also their respective families. The rituals conducted as part of the ceremony signifies a mutual agreement of the family members to the impending wedding between the bride and the groom. Usually, the ceremony is held after seeking the counsel of Vedic astrologers to ensure that the horoscopes of the soon to be bride and the groom are compatible with each other.</p>
      <p>The engagement ceremony is conducted on an auspicious date as per the lunar calendar. The ceremony is presided by a purohit who initiates the formalities by performing Ganapati pooja. The respective families exchange gifts amid the chants of Vedic mantras as a gesture of mutual affection to the betrothed couple. The exchange of gifts will be followed by an exchange of Nischithartha tamboola, which is a collection of fruits and other auspicious material to formalize the commitment.</p>
      <p>After considering the auspicious dates, the purohit will then draft a “Lagna Patrika” containing the details of the soon to be bride and the groom and their families along with the date and time for the wedding. It is then read out loud, seeking affirmation from both the families. Once the contents are approved by both the families, they are considered to have committed to the wedding, which is further sealed by an exchange of rings between the bride and the groom.</p>
      <p>The engagement is usually a small ceremony with immediate family members and close friends in attendance. Vedic astrology plays a huge role in the ceremony.</p>
      <p></p>
      `,
      shortDescription: `
      A prelude to wedding, the engagement ceremony signifies a formal commitment by the prospective bride and the groom, in the presence of their parents, to marry each other. It is conducted by invoking the blessings of gods, exchange of gifts and choosing an auspicious day for the wedding under the guidance of a learned purohit.
      `,
      imgUrls: [
        Engagement,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      helpDetails: `
      <p>On HariGurus, we list purohits who are well versed in Vedic rituals. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services for engagement and wedding-related rituals. You can also avail services from our list of astrologers to match the horoscope and identify the perfect match for those who are near and dear to you</p>
      <p>The purohits will give you a detailed description of the rituals and make necessary preparation, including the necessary pooja material to conduct the ceremony. They will arrive at your location on the preferred date and time and conduct the rituals as prescribed by sacred texts. </p>
      
      `,
      priceWithArticles: '16,500',
      priceWithoutArticles: '11,000',
    },
    wedding: {
      title: 'Wedding',
      description: `
      <p>A traditional Hindu wedding is a collection of various Vedic rituals which signifies an important transition in life. The wedding brings together the bride and the groom to a blissful union, blessed by the gods, families, and friends.</p>
      <p>The wedding rituals start much before the actual wedding ceremony. These rituals conducted by the bride and the groom’s families individually as well as collectively are performed to bring good luck and eliminate any obstacles to the wedding. These rituals are conducted as prescribed in Vedic texts and predominantly include poojas to the family deities and Ganapati.</p>
      <p>Some of the prominent pre-wedding rituals include Naandi, Hoovilya, Chappara Pooja and Vara Pooja. During these rituals, the families of the bride and groom reinforce their commitment and express their approval. All these rituals are performed under the guidance of a learned purohit.</p>
      <p>On the wedding day, the purohit cleanses the wedding altar by performing Mantapa Pooja. The bride and the groom, after having performed other rituals, will be made to sit on the altar within a sacred circle, in front of the fire, symbolizing the fire god Agni. The bride and the groom recite mantras and perform Akshatharopana, praying for their married life to be long and prosperous.</p>
      <p>At a predetermined auspicious time, the groom ties a sacred mangalya thread around the bride’s neck, promising happiness, and a long healthy future. The bride and the groom, with Agni as witness, promise each other of a lifelong friendship, love, and unwavering support to each other through the “thicks and thins” of life. The wedding is finally solemnized by the couple taking seven full circles around the fire, exchanging seven vows in a ritual known as Sapthapadi.</p>
      <p>The wedding ceremony is then concluded with the newlywed couple looking at Arundhati and Dhruva Nakshatras, considered to be the embodiments of the qualities of an ideal husband and wife.</p>
      <p>The wedding ceremony is usually attended by the immediate and extended families and friends of the bride and the groom. The event concludes with a full course meal and tamboola served to everyone in attendance.</p>
      `,
      shortDescription: `
      A Hindu wedding is not just a grand celebration but a combination of various rituals and poojas performed in a particular order. Each ritual has its own significance, and the ceremony culminates with the bride and the groom exchanging vows over seven sacred steps (Saptapadi) in front of Agni as witness.
      `,
      imgUrls: [
        Wedding,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      helpDetails: `
      <p>On HariGurus, we list purohits who are well versed in Vedic rituals. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services for all wedding-related rituals. You can also avail services from our list of astrologers to match the horoscope and identify the auspicious dates for the marriage.</p>
      <p>The purohits will give you a detailed description of the rituals and make necessary preparation, including the necessary pooja material to conduct the ceremony. They will arrive at your location on the preferred date and time and conduct the rituals as prescribed by sacred texts. </p>
      <p></p>
      `,
      priceWithArticles: '38,500',
      priceWithoutArticles: '16,500',
    },

    shashtipoorthiShanti: {
      title: 'Shashtipoorthi Shanti',
      description: `
        <p>The Shashtipoorthi Shanti ceremony is performed to signify the sixtieth birthday of a Hindu male. The ceremony marks the journey of the person through youth and usher blessings of a long, healthy life as they contemplate spiritual development. The ceremony is performed in the presence of the family members and serves as an occasion for the younger generations to express gratitude for the role they played in their lives.</p>
        <p>The Shashtipoorthi Shanti ceremony is performed on the day the person attains sixty years as per their Nakshatra. As a part of the ceremony, on behalf of the person and his wife, the purohit offers prayers to the family deity and performs Aayush, Mrithyunjaya and Navagraha homas to appease the gods and seek their blessings of longevity, health, and wellness. The Navagraha homa also helps rectify any negative planetary influence on the couple.</p>
        <p>The couple will then renew their wedding vows, committing to stand by each other in their new phase of life.</p>
      `,
      shortDescription: `
      The Shashtipoorthi Shanti is a ritual conducted when a Hindu male attains the age of 60. The ceremony marks the preparation of a person to the impending old age and seeks the blessing of gods to bestow good health and a long life. In this ceremony, the couple also renew their wedding vows, committing to stand by each other during this new phase of life.
      `,
      imgUrls: [
        Shashtipoorthi,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      helpDetails: `
      <p>On HariGurus, we list purohits who are well versed in Vedic rituals. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services for Shashtipoorthi Shanti on a particular date and time. If you are unsure about the auspicious date and time, our list of astrologers will help.</p>
      <p>The purohits will give you a detailed description of the rituals and make necessary preparation, including the necessary pooja material to conduct the ceremony. They will arrive at your location on the preferred date and time and conduct the rituals as prescribed by sacred texts.</p>
      `,
      priceWithArticles: '27,500',
      priceWithoutArticles: '16,500',
    },

    bhimarathaShanti: {
      title: 'Bhimaratha Shanti',
      description: `
        <p>Bhima Ratha Shanti is a ceremony conducted when a male family member attains the age of seventy. The rituals involve poojas and homas, seeking the blessings of health and longevity from the family deity and other gods of choice. The ritual is performed with the involvement of the elderly couple in the presence of their family members by a learned purohit who is also well versed in vedic astrology.</p>
        <p>To seek good health, longevity, and a comfortable fulfilling life for the subject, the Purohit will perform Ganapati, Aayush, Lakshmi, Amritha, Mrithyunjaya and Dhanvantari homas, along with Kalashabisheka. These homas will appease the gods responsible for the health, wealth, and life force.</p>
        <p>The rituals are said to remedy negative planetary influences and influence good health. </p>`,

      shortDescription: `
        A ceremony that marks the husband in married relationship reaching the age of 70, Bhima Ratha Shanti brings all the family members together to conduct a series of poojas and homas to seek divine blessings for a long, healthy and happy marital life ahead. The ceremony cleanses the person of any bad karma or sins committed during the lifetime and aids in spiritual well-being.`,
      imgUrls: [
        BhimarathiShanthi,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      helpDetails: `
      <p>On HariGurus, we list purohits who are well versed in Vedic rituals. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services for Bhimaratha Shanti on a particular date and time. If you are unsure about the auspicious date and time, our list of astrologers will help.</p>
      <p>The purohits will give you a detailed description of the rituals and make necessary preparation, including the necessary pooja material to conduct the ceremony. They will arrive at your location on the preferred date and time and conduct the rituals as prescribed by sacred texts. </p>
      `,
      priceWithArticles: '27,500',
      priceWithoutArticles: '16,500',
    },

    gruhapravesha: {
      title: 'Gruhapravesha',
      description: `
        <p>The term “Gruhapravesha'' translates to “entering a house”, and as the name signifies, it is a ritual performed when someone moves into a new place of dwelling. The ritual is performed irrespective of whether the house itself is newly constructed or someone else was previously residing there. When performed by an experienced purohit, the ritual cleanses any presence of negative energy and fills the dwelling with positive energy.</p>
        <p>The purohit will follow Vedic astrology to identify the auspicious date and time at which the pooja may be commenced. The traditional Gruhapravesha rituals span over a period of two days, starting with Ganapati pooja, Navagraha pooja, Vaastu pooja, Vaastu Rakshogana homa, Purnahuti and Mahamangalarthi on the first day. These rituals will help rectify any shortcomings in the Vaastu of the house. On the second day, during the Pravesham muhurat, the purohit will perform a purification ritual by chanting Vedic mantras. It is followed by Gau pooja, and the milk boiling ritual. As a part of the Gruhapravesha ritual, Lakshmi pooja, Lakshmi-Kuber homa, Kula devata homa and Satyanarayana pooja are conducted. Through these poojas, the purohit invokes the blessings of the occupant’s family deity, goddess Lakshmi, Kuber and Vishnu to ensure that the residents are bestowed with good fortune, happiness and health while being protected from negative energies.</p>
        <p>In addition to the pooja, the purohit may also suggest the ideal placement of various articles and areas for different purposes within the home as per the Vaastu shastra.</p>
      `,
      shortDescription: `
      A cleansing ritual conducted whenever someone moves to a new house, Gruhapravesha involves a series of poojas performed on an auspicious day to cleanse the dwelling of any negative energy and seek the blessings of good fortune, love, happiness, and fulfilment from the deities.
      `,
      imgUrls: [
        Gruhapravesha,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      helpDetails: `
      <p>On HariGurus, we list purohits who are well versed in Vedic rituals. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services for Gruhapravesha on a particular date and time. If you are unsure about the auspicious date and time, our list of astrologers will help.</p>
      <p>The purohits will give you a detailed description of the rituals and make necessary preparation, including the necessary pooja material to conduct the ceremony. They will arrive at your location on the preferred date and time and conduct the rituals as prescribed by sacred texts. </p>
      `,
      priceWithArticles: '38,500',
      priceWithoutArticles: '16,500',
    },
    pitruPaksha: {
      title: 'Pitru Paksha',
      description: `
        <p>Pitru Paksha is a fortnight-long auspicious period before the Mahalaya Amavasya during which Hindus pay respects to their deceased ancestors by performing Shraddha and other poojas.  The Shraddha ritual is performed to remember and pray for any deceased family member. During Pitru Paksha, prayers and ritualistic offering of food and water is performed for all the ancestors to appease their souls and wish for them to attain salvation.</p>
        <p>During Pitru Paksha, it is believed that the souls of deceased ancestors visit their descendants in some form or other. To avoid offending them, people are required to follow a pious and disciplined routine during this time. It is also the time when people can do penance and follow Vedic customs to overcome Pitru Dosha by offering prayers to their ancestors.</p>
        <p>Most of the religious duties during the Pitru Paksha are carried out by the male family member. By seeking the guidance of learned purohits, appropriate rituals and remedies can be carried out during this period. The purohits will advise rituals to be performed during the fortnight and they will preside over the Shraddha ceremony to facilitate the repayment of spiritual and existential debt owed by the present descendants to previous generations.</p>
        <p>It is also a time for generosity and those who diligently follow the rituals will benefit from the blessings and protection offered by their ancestors.</p>
      `,
      priceWithArticles: '5,500',
      priceWithoutArticles: '3,500',
      shortDescription: `
      Observed over a fortnight during the Mahalaya lunar phase, Pitru Paksha is considered a period when the ancestors visit their descendants. During this period, poojas and shraddha are performed as an act of remembrance and thanksgiving for their influence and blessings bestowed upon the present and future generations.
      `,

      imgUrls: [
        PitruPaksha,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      helpDetails: `
      <p>On HariGurus, we list purohits who are well versed in Vedic rituals. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services for Pitru Paksha on a particular date and time. If you are unsure about the auspicious date and time, our list of astrologers will help.</p>
      <p>The purohits will give you a detailed description of the rituals and make necessary preparation, including the necessary pooja material to conduct the ceremony. They will arrive at your location on the preferred date and time and conduct the rituals as prescribed by sacred texts.</p>
     
      `,
    },
    shraddha: {
      title: 'Shraddha',
      description: `
        <p>Shraddha is a ritual for remembrance of dead ancestors performed every year on the day of their demise. The Shraddha ceremony is marked by prayers and offerings to ensure the well-being of the departed souls. By honoring the ancestors and wishing for their souls to attain salvation, Shraddha rituals acts as a way to show indebtedness for their role in making the present and future lives possible.</p>
        <p>Those performing the Shraddha ritual will receive the blessings of good luck and fortunes from the ancestors who wish the well-being of the descendants.</p>
        <p>The day to perform Shraddha each year is calculated by a learned purohit or a Vedic astrologer. The day of Shraddha each year is chosen such that it coincides with the death anniversary of the person as per the lunar calendar. On the day of ceremony, the purohit will offer prayers to the gods and ancestors by reciting Vedic mantras, which will be followed by the ritualistic offering of food called Pindadaan to the deceased souls. The offering is prepared by mixing rice, barley, milk, honey, ghee, sugar, and other ingredients, which is then fed to a cow or immersed in a water body.</p>
        <p>The Pindadaan is followed by Tarpan, an offering of water mixed with sesame seeds, barley, darbhe grass and flour to the departed ancestors. The pinda and tarpan offerings will bring satisfaction and peace to the departed souls and bring blessings and good fortunes to the descendants who perform the ceremony.</p>
        <p>The Shraddha ceremony is concluded by offering food to brahmins, followed by family members who were part of the ceremony.</p>
        <p>Depending on the preference, the Shraddha ceremony can be performed either at home or in holy places of religious significance.</p>
      `,
      priceWithArticles: '5,500',
      priceWithoutArticles: '3,500',

      shortDescription: `
      A ceremony of remembrance and offerings to the departed family members, Shraddha is performed to bring peace to their souls. The rituals are carried out every year, preferably on the day their soul departed from earth as per the lunar calendar. The poojas are accompanied by an offering of food to appease their soul and receive blessings in return.
      `,
      imgUrls: [
        Shraddha,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      helpDetails: `
      <p>On HariGurus, we list purohits who are well versed in Vedic rituals. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services for Shraddha on a particular date and time. If you are unsure about the auspicious date and time, our list of astrologers will help.</p>
      <p>The purohits will give you a detailed description of the rituals and make necessary preparation, including the necessary pooja material to conduct the ceremony. They will arrive at your location on the preferred date and time and conduct the rituals as prescribed by sacred texts.</p>
      `,
    },
    uttaraKriyaKarma: {
      title: 'Uttara Kriya Karma',
      description: `
        <p>Uttara Kriya Karma is a collection of rituals to be carried out to mark the end of life and facilitate the transition of recently deceased souls from the physical to a metaphysical world. These rituals help the soul attain salvation while helping the loved ones cope with the loss of a family member or a friend.</p>
        <p>Traditional Uttara Kriya Karma rituals can extend for a duration of fourteen days, starting with funeral preparation to Vaikunta Samaradhane. The rituals are performed by one or more purohits, and the ceremony may vary depending on different factors. In the event of a death, the purohit will conduct Uttara Kriya Karma by performing Apara Kriya Samskara, Prayaschitta homa and other poojas to prepare the body for cremation. It is followed by the collection of ashes during the next two days, and a few daily rituals at the venue of cremation. The collected ashes, known as “Asthi” is then dispersed in a water body by offering prayers. Under the guidance of the purohit, the bereaved family members make offerings of food to the departed soul in the form of Pinda for the next few days as the soul is said to have begun its journey to the gates of Yamaloka – abode of the God of Death. The purohit will perform Yama homa and Shraddha ceremonies to ensure peace and tranquility to the soul.</p>
        <p>On the thirteenth and the fourteenth day, Masika Shraddha and Vaikunta Samaradhane are performed, and a feast is organized for everyone in attendance.</p>
      `,
      shortDescription: `
      A set of rituals performed over a period of 14 days following a person’s demise. Starting with the preparation for cremation, the rituals address various processes that need to be fulfilled in order for the deceased’s soul to attain peace. These vedic rituals also help the bereaved cope with the loss of their dear ones. 
      `,
      imgUrls: [
        UttaraKriyaKarma,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      priceWithoutArticles: '77,000',
      helpDetails: `
      <p>On HariGurus, we list purohits who are well versed in Vedic rituals. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services for Uttara Kriya Karma on a particular date and time or for a full 13 days.</p>
      <p>The purohits will give you a detailed description of the rituals and make necessary preparation, including the necessary pooja material to conduct the ceremony. They will arrive at your location on the preferred date and time and conduct the rituals as prescribed by sacred texts.</p>
      `,
    },
  },
  pujas: {
    satyanarayanaPuja: {
      title: 'Satyanarayana Puja',
      description: `
        <p>Satyanarayana Pooja is performed by all practicing Hindus from all walks of life. The pooja involves invoking the blessings of the God Satyanarayana, an avatar of one of the Hindu trinity, God Vishnu. According to the scriptures, it is believed that the God Satyanarayana appointed himself as the designated protector of the people during Kaliyuga and anyone who performs the ritual will receive his blessings to overcome any challenges in life.</p>
        <p>Satyanarayana Pooja is conducted as a mark of gratitude and to seek divine assistance during the happy as well as challenging times. By performing the pooja diligently, one can ensure happiness, harmony, prosperity, and good fortunes while eliminating negative energies and bad influences in life. The pooja is generally performed as part of almost all auspicious events in the Hindu tradition. </p>
        <p>The pooja is performed on a full moon day, at an auspicious time suggested by Vedic astrologers or purohits. On the pooja day, the participants will observe fasting until the rituals are completed. The pooja rituals are performed according to the sacred texts under the guidance of a purohit. </p>
        <p>Like all auspicious events, the Satyanarayana pooja ritual also begins with Gowri and Ganapati pooja, which will be followed by the recital of Satyanarayana Katha which is a collection of stories on miracles performed by the god. These stories underline the significance of the rituals and the importance of righteous living. It is followed by Ashtottara Pooja where the 108 names of Vishnu are recited to conclude the occasion. </p>
        <p>Depending on the practices and preferences, a homa is also conducted as part of the Satyanarayana pooja ritual. Some of the other prayers that are part of the ritual include Bhoomi pooja, Kalasha pooja, Navagraha pooja, Varuna pooja, Lakshmi Pooja and more.</p>
        <p>Prasadam is distributed to those in attendance once the pooja concludes to break the fast. It will be followed by a traditional meal which is usually prepared for festive occasions.</p>
      `,
      shortDescription: `
      Performing Satyanarayana Pooja places one in the good graces of God Vishnu. It is an important part of most Hindu ceremonies and brings the blessings of good health, wealth, happiness and alleviates all the troubles.
      `,
      imgUrls: [
        SatyaNarayanan,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      priceWithArticles: '11,000',
      priceWithoutArticles: '6,600',
      helpDetails: `
      <p>On HariGurus, we list purohits who are well versed in Vedic rituals. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services for Satyanarayana Pooja on a particular date and time. If you are unsure about the auspicious date and time, our list of astrologers will help.</p>
      <p>The purohits will give you a detailed description of the rituals and make necessary preparation, including the necessary pooja material to conduct the ceremony. They will arrive at your location on the preferred date and time and conduct the rituals as prescribed by sacred texts.</p>
      `,
    },
    lakshmiPuja: {
      title: 'Lakshmi Puja',
      description: `
        <p>Lakshmi Pooja is an auspicious ceremony in which people show reverence and offer thanks to the God Vishnu’s consort and the goddess of wealth and prosperity. By performing the pooja as per established traditions, people will benefit from the goddess’ blessings, resulting in good fortunes and prosperity. Lakshmi pooja is generally performed during Deepawali, Vara Mahalakshmi Vratha, Akshaya Tritiya and Dantheras festivals. The pooja can also be performed at other times, on an auspicious day as per the advice of a purohit. The purohit will suggest suitable days and time to perform the pooja based on Vedic astrology after taking the horoscope of the person performing the pooja into account.</p>
        <p>The purohit will conduct Lakshmi pooja as per the Vedic rituals. Before performing the pooja, the entire house will be cleaned and decorated with mango leaves, flowers, and lamps to invite the goddess. The rituals start with Ganapathi pooja, which is followed by Punya Vachana and Maha Sankalpa. A Kalasha will be set up along with the offering of fruits, puffed rice, corn and other items of significance. The purohit will perform Kalasha pooja, followed by Mahalakshmi pooja while chanting Lakshmi mantra. The pooja is concluded by performing Mahamangalaarathi and distribution of prasadam to everyone in attendance.</p>
      `,
      shortDescription: `
      Also known as Mahalakshmi pooja, the ritual is performed seeking the blessings of the goddess of wealth and prosperity. The pooja also signifies thanksgiving for all the good fortunes bestowed by her grace. 
      `,
      imgUrls: [
        LakshmiPuja,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      priceWithoutArticles: '2,750',
      helpDetails: `
      <p>On HariGurus, we list purohits who are well versed in Vedic rituals. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services for Lakshmi Pooja on a particular date and time. If you are unsure about the auspicious date and time, our list of astrologers will help.</p>
      <p>The purohits will give you a detailed description of the rituals and make necessary preparation, including the necessary pooja material to conduct the ceremony. They will arrive at your location on the preferred date and time and conduct the rituals as prescribed by sacred texts.</p>
      `,
    },
    officePuja: {
      title: 'Office Puja',
      description: `
        <p>The poojas performed in the workplace are slightly different from those performed at homes. However, the aim and the rituals performed share a lot of similarities. With good luck, prosperity, and continuity of business as the main aim, Vedic rituals are conducted when a new office space is set up and on auspicious days at existing places of business.</p>
        <p>Office Pooja involves multiple rituals, and it is conducted on an auspicious day by experienced purohits well versed in Vedic texts and traditions. The pooja starts with invocation of Ganapathi to seek his continued blessings to eliminate an obstruction to the business. It is followed by Navagraha pooja and homa, where prayers are offered to the planetary gods to ward off negative forces and ill effects from planetary influence. Mahalakshmi pooja is then performed to seek the goddess’ blessings for the success and profitability of the business. The rituals also seek blessings for the wellbeing of everyone associated with the business. During the pooja, the purohit also seeks divine blessings for the essential instruments and equipment in the office space to ensure reliability and trouble-free operations.</p>
        <p>The pooja is concluded with the distribution of prasadam and gifts to everyone in attendance. Traditional meals may also be arranged for everyone in the office.</p>
      `,
      shortDescription: `
      Offices are places of business that support the livelihood of employers and employees. To prosper and conduct the business without obstacles, it is a customary practice to seek divine blessings. The pooja includes invocation of gods Ganapathi, Lakshmi and the Navagrahas.
      `,
      imgUrls: [
        OfficePuja,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      priceWithoutArticles: '3,300',
      helpDetails: `
      <p>On HariGurus, we list purohits who are well versed in Vedic rituals. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services for Office Pooja on a particular date and time. If you are unsure about the auspicious date and time, our list of astrologers will help.</p>
      <p>The purohits will give you a detailed description of the rituals and make necessary preparation, including the necessary pooja material to conduct the ceremony. They will arrive at your location on the preferred date and time and conduct the rituals as prescribed by sacred texts.</p>
      `,
    },
  },
  cook: {
    inHouseCooking: {
      title: 'In-house Cooking',
      description: `
      <p>Our curated list includes some of the best cooks known to prepare quality food for any function in any quantity. They will prepare the food at your home or the function venue and serve it to the table.</p>
      `,
      shortDescription: `
      Our curated list includes some of the best cooks known to prepare quality food for any function in any quantity. They will prepare the food at your home or the function venue and serve it to the table.
      `,
      imgUrls: [
        InHouseCooking,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      priceWithArticles: '275/plate',
      priceWithoutArticles: '165/plate',
      helpDetails: `
      <p>On HariGurus, we list cooks who are well versed in traditional cooking. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services on a particular date and time.</p>
      <p>The cooks will arrive at your location on the preferred date and time, make necessary preparations including the necessary food items and prepare your delicious meals in your own Kitchen.</p>
      
      `,
    },
    catering: {
      title: 'Catering',
      description: `
      <p>Our list of professional caterers have years of experience preparing and serving traditional food for all kinds of religious events. Select the caterer and order food based on the type of function or customize your selection based on various available options.</p>
      `,
      shortDescription: `
      Our list of professional caterers have years of experience preparing and serving traditional food for all kinds of religious events. Select the caterer and order food based on the type of function or customize your selection based on various available options.
      `,
      imgUrls: [
        Catering,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      priceWithArticles: '275/plate',
      priceWithoutArticles: '165/plate',
      helpDetails: `
      <p>On HariGurus, we list cooks who are well versed in traditional cooking. Based on their availability and your convenience, you can choose the one who meets the criteria and book their services on a particular date and time.</p>
      <p>The cooks will make necessary preparation, including the necessary food items to prepare your delicious meals. They will prepare at their location and deliver at your doorstep on the preferred date and time.</p>
      
      `,
    },
  },
  astrology: {
    onlineConsultation: {
      title: 'Online Consultation',
      description: `
      <p>You can now avail the services of some of the top notch astrologers from the comfort of your home or office. Book a slot with any of the listed astrologers to gain insights into your past, present and future. You can seek their astrological and spiritual guidance on things of importance and plan accordingly.</p>
      `,
      shortDescription: `
      You can now avail the services of some of the top notch astrologers from the comfort of your home or office. Book a slot with any of the listed astrologers to gain insights into your past, present and future. You can seek their astrological and spiritual guidance on things of importance and plan accordingly.
      `,
      imgUrls: [
        OnlineConsultation,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      priceWithoutArticles: '200',
      helpDetails: `
      
      `,
      ctaText: 'Book Now',
    },
    bookAndVisit: {
      title: 'Book & Visit',
      description: `
      <p>Browse through our list of qualified astrologers along with their expertise and location to find those who meet your requirements. Once you have found what you are looking for, book an appointment and visit them.</p>
      `,
      shortDescription: `
      Browse through our list of qualified astrologers along with their expertise and location to find those who meet your requirements. Once you have found what you are looking for, book an appointment and visit them.
      `,
      imgUrls: [
        BookAndVisit,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      priceWithoutArticles: '200',
      helpDetails: `
      
      `,
      ctaText: 'Book Now',
    },
    qAndA: {
      title: 'Q & A',
      description: `
      <p>Do you have any questions that are bothering you, or curiosity that is making you restless? You can ask them on HariGurus and get relevant answers from qualified astrologers. This service is ideal for quick questions and clarifications. If you want more personalized guidance, we suggest you opt for online consultation or book an appointment for a personal visit.</p>
      `,
      shortDescription: `
      Do you have any questions that are bothering you, or curiosity that is making you restless? You can ask them on HariGurus and get relevant answers from qualified astrologers. This service is ideal for quick questions and clarifications. If you want more personalized guidance, we suggest you opt for online consultation or book an appointment for a personal visit.
      `,
      imgUrls: [
        QAndA,
        'https://swiperjs.com/demos/images/nature-2.jpg',
        'https://swiperjs.com/demos/images/nature-3.jpg',
        'https://swiperjs.com/demos/images/nature-4.jpg',
      ],
      priceWithoutArticles: '200',
      helpDetails: `
      
      `,
      ctaText: 'Book Now',
    },
  },
}
