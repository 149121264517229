import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { UserContext } from '../../context/UserContext'

import './Orders.css'

export default function Orders() {
  const [token] = useContext(AuthContext)
  const [user, , getUserDetails] = useContext(UserContext)

  useEffect(() => {
    token && getUserDetails(token)
  }, [token])

  return (
    <div className='container'>
      <div className='user-details'>
        <div className='card-header'>
          <h1>Orders</h1>
        </div>
        {user.orders.map((order, index) => (
          <div className='order-card' key={index}>
            <div>
              <p>{order.eventName}</p>
              <p className='secondary-details'>Pooja Items: {order.includePoojaItems}</p>
            </div>
            <div>
              <p>{order.eventDate}</p>
              <p className='secondary-details'>{order.eventTime}</p>
            </div>
            <p className='order-price'>₹{order.price}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
