const poojaArticles = [
  "Kumkum",
  "Sandle Sticks/Agara Batti",
  "Sandle Paste/Gandha",
  "Ghee",
  "Lavancha",
  "Nine type of Groceries/Nava Dhanya",
  "Copper Vessel/Tambige",
  "Copper Vessel/Bindige",
  "Brass Plates",
  "Brass Bowls",
  "Homa Materials",
  "Dry Thread Ball",
  "Copper Coins & Nails",
  "Balamuri & Edamuri Conch",
  "Kachora/Gandha Akshate Holder",
  "Akshate Powder",
  "Thirtha Powder",
  "Gou Mutra",
  "Cow Dung Cake",
  "Homa Pot",
  "Pumpkin Tying Thread",
  "Wool Thread",
  "Kankana Thread",
  "Paddy Aralu",
  "Dhoti-9x5, 10x6, 2mtrs",
  "Madi Dhoti",
  "Blouse Piece",
  "Sambrani Powder",
  "All types of Beetlenut",
  "Gejje Vastra",
  "Hoo Batti",
  "Mangalarati Batti",
  "Kaddi Batti",
  "Garland",
  "Honey",
  "Asta Gandha",
  "Kasturi Turmeric",
  "Black Sesame",
  "Brass Diyas",
  "Bells/Ghante",
  "Halagarati",
  "Small Wooden Stools/Mane",
  "Donne",
  "Adike Plates",
  "Rangoli",
  "Rangoli Colour",
  "Red Mud/Kemmannu",
  "Pink Salt",
  "Spatikha",
  "Sandle Sticks Stand",
  "Iron frying Bowl",
  "Pitambari Powder",
  "Ruperi Liquid",
  "Neem Oil",
  "Honge Oil",
  "Neelgiri Oil",
  "Caster Oil",
  "Diya Oil",
  "Jasmine Oil",
  "Cinamon Oil",
  "Dry Fruits",
  "Sugar Candy/Kallu Sakkare",
  "Grandige Items",
  "Panchanga",
  "Cotton",
  "Candle",
  "Gandha Tablet",
  "White Mustard",
  "Gopuram Haldi and Kumkum",
  "Dhoopa",
  "Coffee Cups",
  "Water Cups",
  "Paper plates",
  "Dining Leaf/Mutkada Ele",
  "Ganga Jala",
  "Match Box",
  "Diya Kolave",
  "Rose Water",
  "Mehandi Powder",
  "Baananti Items",
  "Bale Bicchale",
  "Homa Cloth",
  "Navagraha Cloth",
  "Turmeric Sticks",
  "Sammittu",
  "Homa Sticks",
  "Goni Dara",
  "Edible Camphor/Paccha Karpura",
  "Nerale Juice",
  "Tamboola Covers",
  "Pooja Box",
  "Ziplock Covers-All Size",
  "Brass Brundavana",
  "Designer Cotton Garland",
  "Lime Box",
  "Chandra Powder",
  "Peacock Feather/Navilu Gari",
  "Glass Bangles",
  "Plastic Mat",
  "Darbe Mat",
  "Darbe",
  "Marad Jote",
  "Baasinga",
  "Calendar",
  "Rangoli Sticks",
  "Rangoli Plates",
  "Agase Seeds",
  "Anta Vale Kayi",
  "Jaggery",
  "Copra",
  "Mud Diyas",
  "Wedding Peta/Topi",
  "Umbrella",
  "Thaali Thread & Chain",
  "Baagina Items",
  "Towels",
  "Jenivaara/Sacred Thread",
  "Ajwain Water",
  "Mudres",
  "Darbe Hagga",
  "Navaratna Set",
  "Bukkittu",
  "Vibooti Balls",
  "Market Bags",
  "Napkin",
  "Saane Kallu",
  "White Naama",
  "Red Naama",
  "Kavade",
  "Gomati Chakra",
  "Lotus Seeds",
  "Tulasi Beeds Chain",
  "Pooja Broom Stick",
  "Camphor Stove",
  "Sankranti Items",
  "Chalkpiece",
  "Ananthana Dara/Thread",
  "Ananthana Vastra/Cloth"
]

export default poojaArticles