import React, { useState, useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Link } from 'react-router-dom'

import PageNotFound from '../../sections/PageNotFound'

import { serviceDetailsList } from '../../Data/AllServicesList'

import './ServicesDetails.css'

export default function ServicesDetails({ match, location }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [descriptionActive, setDescriptionActive] = useState(true)
  const [helpActive, setHelpActive] = useState(false)

  const servicesName = match.params.serviceName
  const servicesDetailName = match.params.servicesDetail

  if (!serviceDetailsList[servicesName]) {
    return <PageNotFound />
  } else if (!serviceDetailsList[servicesName][servicesDetailName]) {
    return <PageNotFound />
  }

  return (
    <div className='ServicesDetails'>
      <title>Harigurus | {servicesDetailName.charAt(0).toUpperCase() + servicesDetailName.slice(1)}</title>
      <div>
        <section className='as_breadcrum_wrapper'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12 text-center'>
                <ul className='breadcrumb'>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  <li>
                    <Link to={`/services`}>Services</Link>
                  </li>
                  <li>
                    <Link to={`/services/${servicesName}`}>
                      {((string) => {
                        if (string)
                          return (
                            string.charAt(0).toUpperCase() + string.slice(1)
                          )
                        else return ''
                      })(servicesName)}
                    </Link>
                  </li>
                  <li>
                    <Link to={`./${servicesDetailName}`}>
                      {
                        serviceDetailsList[servicesName][servicesDetailName]
                          .title
                      }
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className='as_shopsingle_wrapper as_padderBottom80 as_padderTop80'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-3 col-md-4 col-sm-12'>
                <div className='swiper-slide'>
                  <img
                    src={
                      serviceDetailsList[servicesName][servicesDetailName]
                        .imgUrls[0]
                    }
                    alt='nature'
                  />
                </div>
              </div>
              <div className='col-lg-9 col-md-8 col-sm-12'>
                <div className='as_product_description'>
                  <h1 className='as_margin0 as_padderBottom10'>
                    {serviceDetailsList[servicesName][servicesDetailName].title}
                  </h1>

                  <div className='as_font14 as_padderBottom10'>
                    {
                      ReactHtmlParser(
                        serviceDetailsList[servicesName][servicesDetailName]
                          .description
                      )[0]
                    }
                  </div>
                  <h2 className='as_price'>
                    <span className='starting'>Starting From</span>{' '}
                    {(() => {
                      const { priceWithoutArticles } =
                        serviceDetailsList[servicesName][servicesDetailName]
                      const { priceWithArticles } =
                        serviceDetailsList[servicesName][servicesDetailName]

                      const firstPrice = `₹${priceWithoutArticles}`
                      const secondPrice = `₹${priceWithArticles}`

                      if (!priceWithArticles && !priceWithoutArticles) {
                        return `Price Not Available`
                      }

                      if (!priceWithArticles) {
                        return firstPrice
                      }

                      return `${firstPrice} - ${secondPrice}`
                    })()}
                  </h2>
                  <p className='tandc'>
                    *Prices may vary based on the family's rituals. Please{' '}
                    <a href='tel: 9353066802' className='as_orange'>
                      call us
                    </a>{' '}
                    for more details.
                  </p>
                  <div className='prod_quantity as_padderBottom40'>
                    Quantity
                    <div className='quantity'>
                      <button type='button' className='qty_button minus'>
                        -{' '}
                      </button>
                      <input
                        type='text'
                        id='quantity_6041ce9eca5d6'
                        className='input-text form-control qty text'
                        step={1}
                        min={1}
                        max={100}
                        name='quantity'
                        defaultValue={1}
                        title='Qty'
                        inputMode='numeric'
                      />
                      <button type='button' className='qty_button plus'>
                        +{' '}
                      </button>
                    </div>
                  </div>
                  <div className='product_buy'>
                    <Link
                      className='buy_btn as_btn'
                      to={{
                        pathname: `/checkout`,
                        state: {
                          serviceName:
                            serviceDetailsList[servicesName][servicesDetailName]
                              .title,
                          priceWithoutArticles:
                            serviceDetailsList[servicesName][servicesDetailName]
                              .priceWithoutArticles,
                          priceWithArticles:
                            serviceDetailsList[servicesName][servicesDetailName]
                              .priceWithArticles,
                        },
                      }}
                    >
                      {serviceDetailsList[servicesName][servicesDetailName]
                        .ctaText || 'Book Now'}
                    </Link>
                    {(() => {
                      if (servicesName === 'paurohitya') {
                        return (
                          <>
                            <Link to='../cook' className='ad_wishlist'>
                              Need a Cook?
                              <i className='fa fa-heart-o' aria-hidden='true' />
                            </Link>
                            <Link to='../astrology' className='ad_wishlist'>
                              &nbsp; &nbsp; Fix Muhurtham?
                              <i className='fa fa-heart-o' aria-hidden='true' />
                            </Link>
                          </>
                        )
                      }
                    })()}
                  </div>
                </div>
              </div>
              <div className='col-lg-12 col-md-12'>
                <div className='as_tab_wrapper as_padderTop80'>
                  <ul className='nav nav-tabs'>
                    <li>
                      <a
                        onClick={() => {
                          setDescriptionActive(true)
                          setHelpActive(false)
                        }}
                        className={`${descriptionActive ? 'active' : ''}`}
                      >
                        Description
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          setDescriptionActive(false)
                          setHelpActive(true)
                        }}
                        className={`${helpActive ? 'active' : ''}`}
                      >
                        How do we help?
                      </a>
                    </li>
                  </ul>
                  <div className='tab-content'>
                    <div
                      id='description'
                      className={`tab-pane in ${
                        descriptionActive ? 'active' : ''
                      }`}
                    >
                      <h3 className='as_subheading as_orange'>Description</h3>
                      <div className='as_font14 as_padderBottom20'>
                        {ReactHtmlParser(
                          serviceDetailsList[servicesName][servicesDetailName]
                            .description
                        )}
                      </div>
                    </div>
                    <div
                      id='help'
                      className={`tab-pane in ${helpActive ? 'active' : ''}`}
                    >
                      <h3 className='as_subheading as_orange'>
                        How do we help?
                      </h3>
                      <div className='as_font14 as_padderBottom20'>
                        {ReactHtmlParser(
                          serviceDetailsList[servicesName][servicesDetailName]
                            .helpDetails
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
