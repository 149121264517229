import React from 'react'
import { Link } from 'react-router-dom'

import './MostPopularEvents.css'

import AksharabhyasaIcon from '../../assets/images/event-icon/Aksharabhyasa.svg'
import MundanIcon from '../../assets/images/event-icon/ChaulaIcon.svg'
import NamakarnaIcon from '../../assets/images/event-icon/Namakarna.svg'
import UpanayanaIcon from '../../assets/images/event-icon/Upanayana.svg'
import WeddingIcon from '../../assets/images/event-icon/Wedding.svg'
import EngagementIcon from '../../assets/images/event-icon/Engagement.svg'
import BhimarathaShantiIcon from '../../assets/images/event-icon/Bhimaratha shanthi.svg'
import ShashtipoorthiIcon from '../../assets/images/event-icon/Shashtipoorti Shanti.svg'

const mostPopularEventsList = [
  {
    title: 'Aksharabhyasa',
    iconUrl: AksharabhyasaIcon,
    pageUrl: '/services/paurohitya/aksharabhyasa',
    description: `Start your child’s formal education with goddess Saraswati’s blessings`,
  },
  {
    title: 'Mundan',
    iconUrl: MundanIcon,
    pageUrl: '/services/paurohitya/mundan',
    description: `Cleansing your child’s soul of the past karma as it begins the journey through life`,
  },
  {
    title: 'Namakarna',
    iconUrl: NamakarnaIcon,
    pageUrl: '/services/paurohitya/namakarna',
    description: `Give your child the name blessed by the divine, as they go ahead to forge their destiny `,
  },
  {
    title: 'Upanayana',
    iconUrl: UpanayanaIcon,
    pageUrl: '/services/paurohitya/upanayana',
    description: `Introduce your child to spirituality and the way of life as they enter adolescence `,
  },
  {
    title: 'Engagement',
    iconUrl: EngagementIcon,
    pageUrl: '/services/paurohitya/engagement',
    description: `Enable the bride’s and the groom’s consensual commitment to marriage under the guidance of well-versed purohits`,
  },
  {
    title: 'Wedding',
    iconUrl: WeddingIcon,
    pageUrl: '/services/paurohitya/wedding',
    description: `Perform the wedding of your dear ones with all the key vedic rituals by experienced purohits`,
  },

  {
    title: 'Shashtipoorthi',
    iconUrl: ShashtipoorthiIcon,
    pageUrl: '/services/paurohitya/shashtipoorthiShanti',
    description: `Mark the completion of 60 fruitful years by renewing marriage vows along with divine blessings of a healthy, long life.`,
  },

  {
    title: 'Bhimaratha Shanti',
    iconUrl: BhimarathaShantiIcon,
    pageUrl: '/services/paurohitya/bhimarathaShanti',
    description: `Celebrate the 70th birthday while seeking the blessings of health, happiness and long life`,
  },
]

function mostPopularEventsCard(title, iconUrl, pageUrl, description) {
  return (
      <div key={title} className='as_service_box text-center'>
        <span className='as_icon'>
          <img className='most-popular-icon' src={iconUrl} alt={title} />
        </span>
        <h4 className='as_subheading'>{title}</h4>
        <p>{description}</p>
        <Link to={pageUrl} className='as_link'>
          read more
        </Link>
      </div>
  )
}

export default function MostPopularEvents() {
  return (
    <section className='as_service_wrapper as_padderTop60 as_padderBottom80'>
      <div className='container'>
        <div className='col-lg-12 text-center'>
          <h1 className='as_heading as_heading_center'>Most Popular Events</h1>
          <p className='as_font14 as_padderBottom5'>
            Check out the most in-demand events for your easy reference to book
            our services today!
          </p>
        </div>
        <div className='row'>
          <div className='popular-services-container col-lg-12'>
            {mostPopularEventsList.map(
              ({ title, iconUrl, pageUrl, description }) =>
                mostPopularEventsCard(title, iconUrl, pageUrl, description)
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
