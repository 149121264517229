import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import ServicesCard from '../../components/ServicesCard'
import PageNotFound from '../../sections/PageNotFound'

import { serviceDetailsList } from '../../Data/AllServicesList'

const PurohitServicesList = Object.entries(serviceDetailsList.paurohitya).map(
  ([key, value]) => {
    value.serviceUrl = key
    return value
  }
)

const PoojaServicesList = Object.entries(serviceDetailsList.pujas).map(
  ([key, value]) => {
    value.serviceUrl = key
    return value
  }
)

const CookServicesList = Object.entries(serviceDetailsList.cook).map(
  ([key, value]) => {
    value.serviceUrl = key
    return value
  }
)

const AstrologerServicesList = Object.entries(serviceDetailsList.astrology).map(
  ([key, value]) => {
    value.serviceUrl = key
    return value
  }
)

const paurohitya = PurohitServicesList.map((service) => (
  <ServicesCard key={service.title} {...service} shouldCheckout={true} />
))

const poojas = PoojaServicesList.map((service) => (
  <ServicesCard key={service.title} {...service} shouldCheckout={true} />
))

const cook = CookServicesList.map((service) => (
  <ServicesCard key={service.title} {...service} shouldCheckout={true} />
))

const astrologer = AstrologerServicesList.map((service) => (
  <ServicesCard key={service.title} {...service} shouldCheckout={true} />
))

export default function AllServices({ match }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const serviceName = match.params.serviceName

  const servicesTitle = ((string) => {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1)
    else return ''
  })(match.params.serviceName)

  return (
    <div className='AllServices'>
      <title>Harigurus | {servicesTitle}</title>
      <section className='as_breadcrum_wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h1>
                {servicesTitle === 'Pujas' ? 'Puja' : servicesTitle} Services
              </h1>
              <ul className='breadcrumb'>
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <Link to={`/services`}>Services</Link>
                </li>
                <li>
                  <Link to={`/services/${serviceName}`}>{servicesTitle}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className='as_blog_wrapper as_padderTop80 as_padderBottom80'>
        <div className='container'>
          <div className=''>
            <div className='col-lg-12'>
              <div className='row'>
                <div className='page-content'>
                  <div className='all-services'>
                    {(() => {
                      if (serviceName === 'paurohitya') {
                        return paurohitya
                      } else if (serviceName === 'pujas') {
                        return poojas
                      } else if (serviceName === 'cook') {
                        return cook
                      } else if (serviceName === 'astrology') {
                        return astrologer
                      } else {
                        return <PageNotFound />
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
