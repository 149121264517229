import React, { useEffect, useState } from 'react'
import AirDatepicker from 'air-datepicker'
import localeEn from 'air-datepicker/locale/en'
import { Link } from 'react-router-dom'

import 'air-datepicker/air-datepicker.css'
import './BookAppointment.css'

export default function BookAppointment() {
  const bookingData = {
    date: '',
    time: '',
    priceWithoutArticles: '2,750',
    priceWithArticles: '5,500',
    serviceName: 'Aksharabhyasa',
    fromHomepage: 'Yes',
  }

  useEffect(() => {
    new AirDatepicker('.as_datepicker', {
      locale: localeEn,
      dateFormat(date) {
        return date.toLocaleString('en', {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        })
      },
      onSelect: (e) => {
        bookingData.date = e.formattedDate
      },
    })
    new AirDatepicker('.as_timepicker', {
      locale: localeEn,
      timepicker: true,
      onlyTimepicker: true,
      onSelect: (e) => {
        bookingData.time = e.formattedDate
      },
    })
  })

  return (
    <section className='as_know_sign_wrapper as_padderBottom80 as_padderTop80 book-appointment'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 text-center'>
            <h1 className='as_heading as_heading_center'>Book an Event</h1>
            <p className='as_font14 as_margin0 as_padderBottom50'>
              Find professional purohits, cooks, catering service for any
              religious function or a traditional event at your home, <br />{' '}
              office or an event venue. Select a date and event to learn more
            </p>
            <div className='as_sign_form text-left'>
              <ul>
                <li className='as_form_box'>
                  <h3 className='as_subheading'>Date</h3>
                  <div className='as_input_feild'>
                    <input
                      type='text'
                      className='form-control as_datepicker'
                      placeholder='DD/MM/YYYY'
                      value={bookingData.date}
                      onChange={(e) => {
                        bookingData.date = e.target.value
                      }}
                    />
                    <span>
                      <img src='assets/images/svg/date.svg' alt='' />
                    </span>
                  </div>
                </li>
                <li className='as_form_box'>
                  <h3 className='as_subheading'>Time</h3>
                  <div className='as_input_feild'>
                    <input
                      type='text'
                      className='form-control as_timepicker'
                      placeholder='HH:MM'
                      onChange={(e) => {
                        bookingData.time = e.target.value
                      }}
                    />
                    <span>
                      <img src='assets/images/svg/time.svg' alt='' />
                    </span>
                  </div>
                </li>
                <li className='as_form_box'>
                  <h3 className='as_subheading'>Event</h3>
                  <div className='as_input_feild'>
                    <div className='as_select_box'>
                      <select
                        className='form-control'
                        data-placeholder='Bhagwad Katha'
                        onChange={(e) => {
                          bookingData.serviceName = e.target.value
                          bookingData.priceWithoutArticles =
                            e.target.selectedOptions[0].attributes[0].value
                          bookingData.priceWithArticles =
                            e.target.selectedOptions[0].attributes[1].value
                        }}
                      >
                        <option
                          pricewithoutarticles='5,500'
                          pricewitharticles='2,750'
                          value='Aksharabhyasa'
                        >
                          Aksharabhyasa
                        </option>
                        <option
                          pricewithoutarticles='3,300'
                          pricewitharticles='1,100'
                          value='Mundan'
                        >
                          Mundan
                        </option>
                        <option
                          pricewithoutarticles='7,700'
                          pricewitharticles='13,200'
                          value='Namakarna'
                        >
                          Namakarna
                        </option>
                        <option
                          pricewithoutarticles='11,000'
                          pricewitharticles='22,000'
                          value='Upanayana'
                        >
                          Upanayana
                        </option>
                        <option
                          pricewithoutarticles='11,000'
                          pricewitharticles='16,500'
                          value='Engagement'
                        >
                          Engagement
                        </option>
                        <option
                          pricewithoutarticles='16,500'
                          pricewitharticles='38,500'
                          value='Wedding'
                        >
                          Wedding
                        </option>

                        <option
                          pricewithoutarticles='16,500'
                          pricewitharticles='27,500'
                          value='Sastiapoorthi'
                        >
                          Shashtipoorthi Shanti
                        </option>

                        <option
                          pricewithoutarticles='16,500'
                          pricewitharticles='27,500'
                          value='Bhimarati Shanti'
                        >
                          Bhimaratha Shanti
                        </option>

                        <option
                          pricewithoutarticles='16,500'
                          pricewitharticles='38,500'
                          value='Gruhapravesha'
                        >
                          Gruhapravesha
                        </option>
                        <option
                          pricewithoutarticles='3,500'
                          pricewitharticles='5,500'
                          value='Pitru Paksha'
                        >
                          Pitru Paksha
                        </option>
                        <option
                          pricewithoutarticles='3,500'
                          pricewitharticles='5,500'
                          value='Shraddha'
                        >
                          Shraddha
                        </option>
                        <option
                          pricewithoutarticles='77,000'
                          pricewitharticles=''
                          value='Uttara Kriya Karma'
                        >
                          Uttara Kriya Karma
                        </option>
                        <option
                          pricewithoutarticles='6,600'
                          pricewitharticles='11,000'
                          value='Satyanarayana Puja'
                        >
                          Satyanarayana Puja
                        </option>
                        <option
                          pricewithoutarticles='2,750'
                          pricewitharticles=''
                          value='Lakshmi Puja'
                        >
                          Lakshmi Puja
                        </option>
                        <option
                          pricewithoutarticles='3,300'
                          pricewitharticles=''
                          value='Office Puja'
                        >
                          Office Puja
                        </option>
                      </select>
                    </div>
                  </div>
                </li>
                <li className='as_form_box'>
                  <Link
                    to={{
                      pathname: `/checkout`,
                      state: bookingData,
                    }}
                    className='as_btn'
                  >
                    Book event
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
