import React from 'react'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from '../../context/AuthContext'

import './UserProfile.css'

import editIcon from '../../assets/images/svg/edit-2.svg'
import UserIcon from '../../assets/images/svg/user.svg'

import { UserContext } from '../../context/UserContext'

const UserProfile = () => {
  const [token] = useContext(AuthContext)
  const [user, setUser, getUserDetails, getBaseUrl] = useContext(UserContext)

  const [isEditing, setIsEditing] = useState(false)
  const [editingName, setEditingName] = useState(false)
  const [editingMobile, setEditingMobile] = useState(false)
  const [editingEmail, setEditingEmail] = useState(false)
  const [editingAddress, setEditingAddress] = useState(false)

  const BASE_POST_URL = getBaseUrl()

  const updateUser = async () => {
    const postURL = BASE_POST_URL + 'update'

    try {
      const response = await fetch(postURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Auth: token,
        },
        body: JSON.stringify(user),
      })

      const userData = await response.json()

      if (!userData.message) {
        const { name, email, mobile, imageUrl, address } = userData

        setUser({
          name: name || '',
          email: email || '',
          mobile: mobile || '',
          imageUrl: imageUrl || UserIcon,
          address: address || '',
        })
      }
      console.log(userData)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    token && getUserDetails(token)
  }, [token])

  return token ? (
    <div className='container'>
      <title>Harigurus | User Profile</title>
      <div className='user-details'>
        <div className='card-header'>
          <h1>Account Settings</h1>
        </div>
        <div className='detail-row'>
          <div className='detail-type'>
            <p>Name</p>
          </div>
          <div
            onClick={() => {
              setEditingName(true)
              setEditingMobile(false)
              setEditingEmail(false)
              setEditingAddress(false)
              setIsEditing(true)
            }}
            className={`detail-value ${
              editingName || !user.name ? 'editing' : ''
            }`}
          >
            <p>{user.name}</p>
            <input
              className='form-control'
              type='text'
              value={user.name}
              placeholder='Please enter your name'
              onChange={(e) => {
                setUser((prevState) => {
                  return { ...prevState, name: e.target.value }
                })
              }}
            />
          </div>
          <div className='edit-detail'>
            <img
              src={editIcon}
              onClick={() => {
                setEditingName(true)
                setEditingMobile(false)
                setEditingEmail(false)
                setEditingAddress(false)
                setIsEditing(true)
              }}
              alt='editIcon'
            />
          </div>
        </div>
        <div className='detail-row'>
          <div className='detail-type'>
            <p>Mobile Number</p>
          </div>
          <div
            onClick={() => {
              setEditingName(false)
              setEditingMobile(true)
              setEditingEmail(false)
              setEditingAddress(false)
              setIsEditing(true)
            }}
            className={`detail-value ${
              editingMobile || !user.mobile ? 'editing' : ''
            }`}
          >
            <p>{user.mobile}</p>
            <input
              className='form-control'
              type='tel'
              placeholder='Please update your mobile number'
              value={user.mobile}
              onChange={(e) => {
                setUser((prevState) => {
                  return { ...prevState, mobile: e.target.value }
                })
              }}
            />
          </div>
          <div className='edit-detail'>
            <img
              src={editIcon}
              onClick={() => {
                setEditingName(false)
                setEditingMobile(true)
                setEditingEmail(false)
                setEditingAddress(false)
                setIsEditing(true)
              }}
              alt='editIcon'
            />
          </div>
        </div>
        <div className='detail-row'>
          <div className='detail-type'>
            <p>Email</p>
          </div>
          <div
            onClick={() => {
              setEditingName(false)
              setEditingMobile(false)
              setEditingEmail(true)
              setEditingAddress(false)
              setIsEditing(true)
            }}
            className={`detail-value ${
              editingEmail || !user.email ? 'editing' : ''
            }`}
          >
            <p>{user.email}</p>
            <input
              className='form-control'
              type='email'
              placeholder='Please update your email'
              value={user.email}
              onChange={(e) => {
                setUser((prevState) => {
                  return { ...prevState, email: e.target.value }
                })
              }}
            />
          </div>
          <div className='edit-detail'>
            <img
              src={editIcon}
              onClick={() => {
                setEditingName(false)
                setEditingMobile(false)
                setEditingEmail(true)
                setEditingAddress(false)
                setIsEditing(true)
              }}
              alt='editIcon'
            />
          </div>
        </div>
        <div className='detail-row'>
          <div className='detail-type'>
            <p>Address</p>
          </div>
          <div
            onClick={() => {
              setEditingName(false)
              setEditingMobile(false)
              setEditingEmail(false)
              setEditingAddress(true)
              setIsEditing(true)
            }}
            className={`detail-value ${
              editingAddress || !user.address ? 'editing' : ''
            }`}
          >
            <p>{user.address}</p>
            <input
              className='form-control'
              type='text'
              value={user.address}
              placeholder='Please update your address'
              onChange={(e) => {
                setUser((prevState) => {
                  return { ...prevState, address: e.target.value }
                })
              }}
            />
          </div>
          <div className='edit-detail'>
            <img
              src={editIcon}
              onClick={() => {
                setEditingName(false)
                setEditingMobile(false)
                setEditingEmail(false)
                setEditingAddress(true)
                setIsEditing(true)
              }}
              alt='editIcon'
            />
          </div>
        </div>

        <div className='detail-row actions-row'>
          <div className='detail-type'></div>
          <div className='detail-value'></div>
          <div className='edit-detail'>
            <span
              onClick={() => {
                setEditingName(false)
                setEditingMobile(false)
                setEditingEmail(false)
                setEditingAddress(false)
                setIsEditing(false)
                updateUser()
              }}
              className={`as_btn save-btn ${isEditing ? 'editing' : ''}`}
            >
              Save
            </span>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className='container'>
      <h1>Please login</h1>
    </div>
  )
}

export default UserProfile
