import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import emailjs from 'emailjs-com'

import './Contact.css'

const Contact = () => {
  const [messageSent, setMessageSent] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const sendMessage = (e) => {
    e.preventDefault()

    // //My Mail

    // emailjs
    //   .sendForm(
    //     'service_41ltenp',
    //     'template_9hxofan',
    //     e.target,
    //     'user_0ZIyW4XZuIFbQzbYm3crk'
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text)
    //     },
    //     (error) => {
    //       console.log(error.text)
    //     }
    //   )

    //Harigurus Mail

    emailjs
      .sendForm(
        'service_p6qjgku',
        'template_vrn25ha',
        e.target,
        'user_S89rFK9sWCIgbwBrBLdYf'
      )
      .then(
        (result) => {
          console.log(result.text)
        },
        (error) => {
          console.log(error.text)
        }
      )

    setMessageSent(true)
    setTimeout(() => {
      setMessageSent(false)
    }, 3000)
    e.target.reset()
  }

  return (
    <div className='Contact'>
      <title>Harigurus | Contact</title>
      <section className='as_breadcrum_wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h1>Contact</h1>
              <ul className='breadcrumb'>
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <Link to={`/contact`}>Contact</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className='as_contact_section as_padderTop80'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-12'>
              <div className='as_contact_info'>
                <h1 className='as_heading'>Contact Information</h1>
                <p className='as_font14 as_margin0'></p>
                <div className='row'>
                  <div className='col-lg-6 col-md-12'>
                    <div className='as_info_box'>
                      <span className='as_icon'>
                        <img src='assets/images/svg/call1.svg' alt='' />
                      </span>
                      <div className='as_info'>
                        <h5>Call Us</h5>
                        <a
                          href='tel: 9353066802'
                          className='as_margin0 as_font14'
                        >
                          9353066802
                        </a>
                        <a
                          href='tel: 8079687199'
                          className='as_margin0 as_font14'
                        >
                          {' '}
                          8079687199
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-12 hg-email'>
                    <div className='as_info_box'>
                      <span className='as_icon'>
                        <img src='assets/images/svg/mail.svg' alt='' />
                      </span>
                      <div className='as_info'>
                        <h5>Mail Us</h5>
                        <p className='as_margin0 as_font14'>
                          <a
                            href='mailto: harigurus@gmail.com'
                            target='_blank'
                            rel='noreferrer'
                          >
                            harigurus2021@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-12'>
              <div className='as_contact_form'>
                <h4 className='as_subheading'>Have A Question / Feedback?</h4>
                <form onSubmit={sendMessage}>
                  <div className='form-group'>
                    <label>Name</label>
                    <input type='text' className='form-control' name='name' />
                  </div>
                  <div className='form-group'>
                    <label>Mobile</label>
                    <input
                      type='text'
                      className='form-control'
                      name='mobile'
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <label>Email</label>
                    <input type='text' className='form-control' name='email' />
                  </div>
                  <div className='form-group'>
                    <label>Question / Feedback</label>
                    <textarea className='form-control' name='message' />
                  </div>
                  <button
                    className={messageSent ? 'as_btn feedback-sent' : 'as_btn'}
                  >
                    {messageSent ? 'Submitted' : 'Submit'}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='as_map_section'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.247773206465!2d77.5632613148211!3d12.891782990908276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTLCsDUzJzMwLjQiTiA3N8KwMzMnNTUuNiJF!5e0!3m2!1sen!2sin!4v1635180161361!5m2!1sen!2sin'
          width='100%'
          height='743px'
          style={{ border: 0 }}
          allowFullScreen
          loading='lazy'
        />
      </section>
    </div>
  )
}

export default Contact
