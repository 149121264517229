import { Route, Switch } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'

import './Theme.css'
import './App.css'

import Home from './pages/home/Home'
import Header from './sections/Header'
import Footer from './sections/Footer'
import Copyright from './sections/Copyright'
import Services from './pages/services/Services'
import AllServices from './pages/AllServices/AllServices'
import ServicesDetails from './pages/serviceDetails/ServicesDetails'
import Profiles from './pages/Profiles/Profiles'
import Contact from './pages/Contact/Contact'
import About from './pages/About/About'
import Checkout from './pages/Checkout/Checkout'
import UserProfile from './pages/UserProfile/UserProfile'
import Orders from './pages/Orders/Orders'
import PageNotFound from './sections/PageNotFound'
import { UserContext } from './context/UserContext'
import { AuthContext } from './context/AuthContext'
import Alert from './components/Alert/Alert'

function App() {
  const { 2: getUserDetails } = useContext(UserContext)
  const [token, , showAlert] = useContext(AuthContext)


  useEffect(() => {
    token && getUserDetails(token)
  }, [])

  return (
    <div className='App'>
      <div className='as_main_wrapper'>
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/services' component={Services} />
          <Route exact path='/services/:serviceName' component={AllServices} />
          <Route
            exact
            path='/services/:serviceName/:servicesDetail'
            component={ServicesDetails}
          />
          <Route exact path='/profiles' component={Profiles} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/about' component={About} />
          <Route exact path='/checkout' component={Checkout} />
          <Route exact path='/user/profile' component={UserProfile} />
          <Route exact path='/user/orders' component={Orders} />
          <Route path='*' exact component={PageNotFound} />
        </Switch>
        <Footer />
        <Copyright />
        {showAlert && <Alert />}
      </div>
    </div>
  )
}

export default App
