import { createContext, useState } from 'react'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'))
  const [showAlert, setShowAlert] = useState(false)

  return (
    <AuthContext.Provider value={[token, setToken, showAlert, setShowAlert]}>
      {children}
    </AuthContext.Provider>
  )
}
